import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CSpinner,
	CButton,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 		= require('../../package.json')
const Kasirpiutang 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uFirstload,setFirstload]	= React.useState("YA"); 
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END VIEWS_VARS--/

	//---DLGVIEW_VARS--/
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewjenis,setDlgviewjenis] 	= React.useState("");
	const [uDlgviewheader,setDlgviewheader]	= React.useState("");
	//---END DLGVIEW_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggleDlgview = () =>{ setDlgviewshow(false) }
	const hdlKlikDetilitem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		setRowselect(vRowidx);
		setDlgviewjenis("DETILITEM");
		setDlgviewheader("Detil Transaksi: "+vCaptionUpper);

		const vItemsArr	= uDatamainArr[vArridx].items_arr || []
		if(vItemsArr.length > 0) {
			setDlgviewshow(true); return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikHistoribayar = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		setRowselect(vRowidx);

		if(parseInt(uDatamainArr[vArridx].terbayar) <= 0) {
			showToast("Belum Ada Data Pembayaran !"); return;
		}

		setDlgviewjenis("HISTORIBAYAR");
		setDlgviewheader("Pembayaran Transaksi: "+vCaptionUpper);

		const vBayarArr	= uDatamainArr[vArridx].bayar_arr || []
		if(vBayarArr.length > 0) {
			setDlgviewshow(true); return;
		}

		apiLoadhistori(_IDTABEL);
	}
	const hdlKlikBayar = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vRowidx);

		uDatamainArr[vArridx].init_process = true;setEfekview(!uEfekview);
		uNavigate("/subbilling/kasir/bayar/"+_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_kasirpiutang || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initResetDlgview = () => {
		//console.log("(Kasirpiutang - initResetDlgview) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)
		setDlgviewheader("")
		setDlgviewjenis("")
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_kasirpiutang || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg) === parseInt(uPageActive));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none"><th colSpan={2}>{vMin} - {vMax}</th></tr>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_nilaipiutang||"Nilai Piutang"}<div>(Rp)</div></CCol>
			<CCol md="2" className="">{uBahasaObj.word_terbayar||"Terbayar"}<div>(Rp)</div></CCol>
			<CCol md="2" className="">{uBahasaObj.caption_sisapiutang||"Sisa Piutang"}<div>(Rp)</div></CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-md-none">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,userinput,pasien_caption,tgl_piutang,
				nilai_netto,terbayar,init_process,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (pasien_caption||"").toUpperCase();

			const vPasiencaption	= vKeyword==="" ? (pasien_caption||"") 
				: (pasien_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-md-start d-none d-md-block">{UFunc.TglAngka(tgl_piutang)}</CCol>
				<CCol xs="12" md="2" className="px-1 text-start d-flex justify-content-between">
					<div className="text-capitalize text-info">
						{UFunc.renderHTML(vPasiencaption)}
					</div>

					{(init_process||false)===true ? (
						<CSpinner color="primary" className="d-md-none" size="sm"/>
					) : (
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm"
							className="p-0" color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikDetilitem(id)}>
							<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
							Detil Item Terapi
						</CDropdownItem>

						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHistoribayar(id)}>
							<CIcon icon="cilMoney" className="classikonbox mx-0 me-2" height={25}/>
							Histori Pembayaran
						</CDropdownItem>

						<CDropdownDivider/>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikBayar(id)}>
							<CIcon icon="cilChevronDoubleRight" className="classikonbox mx-0 me-2 bg-dark text-white" height={25}/>
							Bayar Kasir
						</CDropdownItem>
						</CDropdownMenu>
						</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">{UFunc.TglAngka(tgl_piutang)}</CCol>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_nilaipiutang||"Nilai Piutang"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 fw-bolder">{UFunc.formatAngka(nilai_netto)}</CCol>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"Terbayar"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1">{UFunc.formatAngka(terbayar)}</CCol>
				
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_sisapiutang||"Sisa Piutang"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 fw-bolder text-danger">
					{UFunc.formatAngka(parseFloat(nilai_netto) - parseFloat(terbayar))}
				</CCol>

				<CCol md="2" className="px-0 text-md-center d-none d-md-block">
					{(init_process||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CDropdown>
					<CDropdownToggle caret={false} size="sm"
						className="p-0" 
						color="transparent">
						<CIcon icon="cilChevronBottom" className="classikonbox" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikDetilitem(id)}>
						<CIcon icon="cilFindInPage" className="classikonbox me-2" height={25}/>
						Detil Item Terapi
						</CDropdownItem>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHistoribayar(id)}>
						<CIcon icon="cilMoney" className="classikonbox me-2" height={25}/>
						Histori Pembayaran
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>

					<CTooltip content={"--"+(uBahasaObj.caption_bayarpiutang||"Bayar Piutang")+": "+vCaptionUpper}>
					<CButton color="dark" size="sm" className="py-1"
						onClick={()=>hdlKlikBayar(id)}>
						Bayar{" "}<CIcon icon="cilChevronDoubleRight" className="align-middle"/>
					</CButton>
					</CTooltip>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)//>
		
		if(uDlgviewjenis === "DETILITEM") return contentDlgviewitem()
		if(uDlgviewjenis === "HISTORIBAYAR") return contentDlgviewhistori()
		
		return (<></>)//>
	}
	const contentDlgviewitem = () => {
		//console.log("(Kasirpiutang - contentDlgviewitem) uIDselect => "+uIDselect);
		if(uDlgviewjenis!=="DETILITEM") return ""
		if(uIDselect <= 0) return "";

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,uIDselect)
		const vItemsArr	= uDatamainArr[vArridx].items_arr || []

		return (
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
		<CCard className="h-100">
		<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Transaksi</small></CCardHeader>
		<CCardBody>
		<small>
			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Tgl Periksa</CCol> 
			<CCol xs="8" className="text-end">{UFunc.TglAngka(uDatamainArr[vArridx].tgl_piutang)}</CCol> 
			</CRow> 

			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Nama Dokter</CCol> 
			<CCol xs="8" className="text-end text-primary">
				<>{(uDatamainArr[vArridx].nama_dokter||"UNDF")}</>
			</CCol> 
			</CRow> 
			
			{parseInt(uDatamainArr[vArridx].id_asuransi) > 0 && (
			<CRow className="">
			<CCol xs="5" className="classfontsmaller" style={{color:"purple"}}>Asuransi</CCol> 
			<CCol xs="7" className="text-end" style={{color:"purple"}}>
				<b>{(uDatamainArr[vArridx].nama_asuransi||"-")}</b>
			</CCol> 
			</CRow> 
			)}

			<div className="my-2 classborderbottom"/>

			{(parseInt(uDatamainArr[vArridx].diskon) > 0 || 
				parseInt(uDatamainArr[vArridx].nilai_asuransi) > 0) && (
			<CRow>
				<CCol xs="4" className="classfontsmaller">Total Terapi</CCol> 
				<CCol xs="8" className="text-end">Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_bruto)}</CCol> 
			</CRow> 
			)}

			{parseInt(uDatamainArr[vArridx].diskon) > 0 && (
			<CRow className="">
				<CCol xs="4" className="classfontsmaller">Diskon</CCol> 
				<CCol xs="8" className="text-end text-danger">Rp{UFunc.formatAngka(uDatamainArr[vArridx].diskon)}</CCol> 
			</CRow> 
			)}

			{parseInt(uDatamainArr[vArridx].id_asuransi) > 0 && (
			<CRow className="">
				<CCol xs="4" className="classfontsmaller" style={{color:"purple"}}>Nilai Asuransi</CCol> 
				<CCol xs="8" className="text-end" style={{color:"purple"}}>Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_asuransi)}</CCol> 
			</CRow> 
			)}

			{(parseInt(uDatamainArr[vArridx].diskon) > 0 || 
				parseInt(uDatamainArr[vArridx].nilai_asuransi) > 0) && (
			<div className="my-2 classborderbottom"/>
			)}

			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Nilai Transaksi</CCol> 
			<CCol xs="8" className="text-end fw-bolder">Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_netto)}</CCol> 
			</CRow>
		</small>
		</CCardBody>
		</CCard>
		</CCol>

		<CCol className="mb-3">
		<CCard className="h-100">
		<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Item Terapi</small></CCardHeader>
		<CCardBody>
		<small>
		{vItemsArr.map((vItems,vKeys)=>{
			const {
				id,caption_terapi,nilai_terapi,qty,diagnosa,diskon_terapi
			} = vItems;

			return (
			<CRow className={"mx-0 py-1"+(vKeys < vItemsArr.length -1 ? " classborderbottom":"")} key={vKeys}>
			<CCol xs="1" className="text-end px-1">{(vKeys+1)}.</CCol> 
			<CCol xs="8" className="text-start px-1">
				<div>{caption_terapi||"UNDF"}</div>
				{parseFloat(nilai_terapi||0)>0 && (
				<small>(
					<b>{qty}</b>unt
					<span className="mx-1">x</span>
					{UFunc.formatAngka(nilai_terapi)}
					)
				</small>
				)}

				{parseFloat(diskon_terapi||0) > 0 && (
				<small className="text-danger ms-1">- (
					{UFunc.formatAngka((parseFloat(diskon_terapi)||0) * (parseInt(qty)||1))}
				)
				</small>
				)}

				{(diagnosa||"")!=="" && (
					<small className="d-block">
					<i>{diagnosa}</i>
					</small>
				)}
			</CCol> 
			<CCol xs="3" className="text-end fw-bolder text-success px-0 classfontsmaller">
				{UFunc.formatAngka((parseFloat(nilai_terapi||0) - parseFloat(diskon_terapi||0)) * parseInt(qty||1))}</CCol> 
			</CRow>
			)
		})}
		</small>
		</CCardBody>
		</CCard>
		</CCol>
		</CRow>
		)//>
	}
	const contentDlgviewhistori = () => {
		if(uDlgviewjenis!=="HISTORIBAYAR") return ""
		if(uIDselect <= 0) return "";

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,uIDselect)
		const vBayarArr	= uDatamainArr[vArridx].bayar_arr || []

		return (
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
		<CCard className="h-100">
		<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Transaksi</small></CCardHeader>
		<CCardBody>
		<small>
			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Tgl Periksa</CCol> 
			<CCol xs="8" className="text-end">{UFunc.TglAngka(uDatamainArr[vArridx].tgl_piutang)}</CCol> 
			</CRow> 

			<CRow>
			<CCol xs="4" className="classfontsmaller">Nama Dokter</CCol> 
			<CCol xs="8" className="text-end text-primary">
				<>{(uDatamainArr[vArridx].nama_dokter||"UNDF")}</>
			</CCol> 
			</CRow> 

			{parseInt(uDatamainArr[vArridx].id_asuransi) > 0 && (
			<CRow>
			<CCol xs="5" className="classfontsmaller" style={{color:"purple"}}>Asuransi</CCol> 
			<CCol xs="7" className="text-end" style={{color:"purple"}}>
				<b>{(uDatamainArr[vArridx].nama_asuransi||"-")}</b>
			</CCol> 
			</CRow>
			)}

			<div className="my-2 classborderbottom"/>

			{(parseInt(uDatamainArr[vArridx].diskon) > 0 || 
				parseInt(uDatamainArr[vArridx].nilai_asuransi) > 0) && (
			<CRow>
				<CCol xs="4" className="classfontsmaller">Total Terapi</CCol> 
				<CCol xs="8" className="text-end">Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_bruto)}</CCol> 
			</CRow> 
			)}

			{parseInt(uDatamainArr[vArridx].diskon) > 0 && (
			<CRow>
				<CCol xs="4" className="classfontsmaller">Diskon</CCol> 
				<CCol xs="8" className="text-end text-danger">
					Rp{UFunc.formatAngka(uDatamainArr[vArridx].diskon)}
				</CCol> 
			</CRow> 
			)}

			{parseInt(uDatamainArr[vArridx].id_asuransi) > 0 && (
			<CRow>
				<CCol xs="4" className="classfontsmaller" style={{color:"purple"}}>Nilai Asuransi</CCol> 
				<CCol xs="8" className="text-end" style={{color:"purple"}}>
					Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_asuransi)}
				</CCol> 
			</CRow> 
			)}

			{(parseInt(uDatamainArr[vArridx].diskon) > 0 || 
				parseInt(uDatamainArr[vArridx].nilai_asuransi) > 0) && (
			<div className="my-2 classborderbottom"/>
			)}

			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Nilai Transaksi</CCol> 
			<CCol xs="8" className="text-end fw-bolder">Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_netto)}</CCol> 
			</CRow>

			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Terbayar</CCol> 
			<CCol xs="8" className="text-end text-success">Rp{UFunc.formatAngka(uDatamainArr[vArridx].terbayar)}</CCol> 
			</CRow>

			<div className="my-2 classborderbottom"/>

			<CRow className="">
			<CCol xs="4" className="classfontsmaller">Sisa Piutang</CCol> 
			<CCol xs="8" className="text-end fw-bolder text-danger">
				Rp{UFunc.formatAngka(parseFloat(uDatamainArr[vArridx].nilai_netto) - parseFloat(uDatamainArr[vArridx].terbayar))}
			</CCol> 
			</CRow>
		</small>
		</CCardBody>
		</CCard>
		</CCol>

		<CCol className="mb-3">
		<CCard className="h-100">
		<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Histori</small></CCardHeader>
		<CCardBody>
		<small>
		{vBayarArr.map((vItems,vKeys)=>{
			const {
				id,tgl_bayar,no_nota,nilai_bayar
			} = vItems;

			return (
			<CRow className={"mx-0 py-1"+(vKeys < vBayarArr.length -1 ? " classborderbottom":"")} key={vKeys}>
			<CCol xs="1" className="text-end px-1">{(vKeys+1)}.</CCol> 
			<CCol xs="8" className="text-start px-1">
				{UFunc.TglAngka(tgl_bayar)} &middot; <b>{no_nota||""}</b>
			</CCol> 
			<CCol xs="3" className="text-end fw-bolder text-success px-0">{UFunc.formatAngka(nilai_bayar)}</CCol> 
			</CRow>
			)
		})}
		</small>
		</CCardBody>
		</CCard>
		</CCol>
		</CRow>
		)//>
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE		= parseInt(_PAGE) || 0;
		setHtml500msg();

		const vKeyword 		= uKeywordObj.kwd_kasirpiutang || "";
		const vHandelview	= (_PAGE <= 0 || uFirstload==="YA")?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setDatamainArr(vTmpObj);
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			send_maxdata	: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasir/ld_piutang";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr);				

				setFirstload("TIDAK");
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kasirpiutang - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		uDatamainArr[vArridx].init_process = true;
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		console.log("(Kasirpiutang - apiProsessimpan) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].init_process = false;
			setDlgviewshow(true)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasir/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].init_process = false;
			if(output_string.tampil) {
				console.log("(Kasirpiutang - apiLoaditem) output_string.detilitem : "+output_string.detilitem);
				uDatamainArr[vArridx].items_arr = JSON.parse(output_string.detilitem||"[]");
				setDlgviewshow(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang - apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgviewshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].init_process = false;
			console.log("(Kasirpiutang - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadhistori = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		uDatamainArr[vArridx].init_process = true
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			uDatamainArr[vArridx].init_process = false;
			setDlgviewshow(true);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasir/ld_histori";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].init_process = false;
			if(output_string.tampil) {
				//console.log("(Kasirpiutang - apiLoadhistori) output_string.historibayar : "+output_string.historibayar);
				uDatamainArr[vArridx].bayar_arr = JSON.parse(output_string.historibayar||"[]");
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirpiutang - apiLoadhistori) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].init_process = false;
			console.log("(Kasirpiutang - apiLoadhistori) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		setDlgviewshow(false);
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setIDselect(0);
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{},[uHandelTambah])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		if(!uIsDlgviewshow) 
			{ initResetDlgview(); return; }
	},[uIsDlgviewshow])
	//console.log("Kasirpiutang - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<span className="text-primary">{uBahasaObj.caption_datapiutang||"Data Piutang"}</span>
		
		<div>
			<small className="d-md-none">({(()=>{
				const vKeyword = (uKeywordObj.kwd_kasirpiutang||"").trim();
				if(vKeyword.length < 3) return (<>&#8734;</>);

				return UFunc.formatAngka(uJmlData)
			})()})</small>
			<span className="d-md-none mx-1">&middot;</span>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
			</span>
		</div>
		</CCardHeader>
		<CCardBody className="px-0">{contentMain()}</CCardBody>
		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>
		</>
	)
}	

export default Kasirpiutang