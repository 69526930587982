import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
	CButton,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Terapicabang 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : 100;//(uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	const [uFirstload,setFirstload]		= React.useState(true);
	const [uTerapimainArr,setTerapimainArr]	= React.useState([]);
	const [uTerapimainID,setTerapimainID]	= React.useState(0);

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit		= (localStorage.getItem("lastcabangid")||"0")
	const uCabangIDselected	= (localStorage.getItem("cabangidselect")||uCabangIDinit)
	const uCabangselectObj	= uDatacabangArr.filter(vItems=> 
		(vItems.id).toString()===uCabangIDselected.toString());
	//--END VIEWS_VARS--/

	//---TABEL_VARS--/
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const [uInpbiayabahanedit,setInpbiayabahanedit]	= React.useState(0);
	const [uInpbiayajasedit,setInpbiayajasaedit]	= React.useState(0);
	//---END TABEL_VARS--/

	//---FORM_VARS--/
	const [isShowForm,setShowForm]					= React.useState(false);
	const [uInpcaptionterapi,setInpcaptionterapi] 	= React.useState("");
	const [uHeaderdlg,setHeaderdlg]					= React.useState("");
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlg = () =>{ setShowForm(false) }
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		setInpcaptionterapi(uDatamainArr[vIdxObj].terapi_caption||"");
		setInpbiayabahanedit(uDatamainArr[vIdxObj].biaya_bahan||0);
		setInpbiayajasaedit(uDatamainArr[vIdxObj].biaya_jasa||0);

		setHeaderdlg("Edit Tarif Terapi");
		setShowForm(true)
	}
	const hdlKlikInitedit = (_IDTABEL,_FIELDEDIT) => {
		initTabelawal();
		_IDTABEL 	= parseInt(_IDTABEL||0);
		_FIELDEDIT 	= (_FIELDEDIT||"").trim();
		if(_IDTABEL <= 0) return;
		if(_FIELDEDIT === "") return;

		const vIdxObj = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vIdxObj].init_fieldedit = _FIELDEDIT;
		let vElFocus;
		if(_FIELDEDIT==="BIAYABAHAN") {
			vElFocus = ("inpbiayabahan"+_IDTABEL);
			setInpbiayabahanedit(uDatamainArr[vIdxObj].biaya_bahan||0);
		}else if(_FIELDEDIT==="BIAYAJASA") {
			vElFocus = ("inpbiayajasa"+_IDTABEL);
			setInpbiayajasaedit(uDatamainArr[vIdxObj].biaya_jasa||0);
		}

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vLokalfokus = document.getElementById(vElFocus);
			vLokalfokus && vLokalfokus.focus();
		},350);
		//setEfekview(!uEfekview);
	}
	const hdlKDownedit = (_IDTABEL,_FIELDEDIT,_EVN) => {
		//alert(_EVN.which)
		if(_EVN.which === 27) {
			_EVN.preventDefault();
			initTabelawal();
			return;
		}
		if(_EVN.which === 13) {
			_EVN.preventDefault();
			_EVN.stopPropagation();
			hdlKlikeditOK(_IDTABEL,_FIELDEDIT);
			return;
		}
	}
	const hdlKlikeditOK = (_IDTABEL,_FIELDEDIT) => {
		let vElFocus;
		if(_FIELDEDIT === "BIAYABAHAN") {
			vElFocus = document.getElementById("inpbiayabahan"+_IDTABEL);
			if(parseFloat(uInpbiayabahanedit) < 0) {
				vElFocus && vElFocus.focus();
				showToast("Biaya Bahan Tidak Valid !"); return;
			}
		}
		if(_FIELDEDIT === "BIAYAJASA") {
			vElFocus = document.getElementById("inpbiayajasa"+_IDTABEL);
			if(parseFloat(uInpbiayajasedit) < 0) {
				vElFocus && vElFocus.focus();
				showToast("Biaya JASA Tidak Valid !"); return;
			}
		}

		apiProsesharga(_IDTABEL,_FIELDEDIT)
	}
	const hdlKlikDlgsimpan = () => {
		let vElFocus = document.getElementById("inpbiayabahan");
		if(parseFloat(uInpbiayabahanedit) < 0) {
			vElFocus && vElFocus.focus();
			showToast("Biaya Bahan Tidak Valid !"); return;
		}
		vElFocus = document.getElementById("inpbiayajasa");
		if(parseFloat(uInpbiayajasedit) < 0) {
			vElFocus && vElFocus.focus();
			showToast("Biaya JASA Tidak Valid !"); return;
		}

		apiProsessimpan(uIDselect);
	}

	//--END HANDEL--/

	//--INIT--/
	const initTabelawal	= () => {
		uDatamainArr.map(vItems=>{
			vItems.init_process = false;
			vItems.init_fieldedit = "";
		});
		setInpcaptionterapi("")
		setInpbiayabahanedit(0)
		setInpbiayajasaedit(0)
		setEfekview2(!uEfekview2)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_terapicabang || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		let vDataviewArr	= uDatamainArr; 
		if((parseInt(uTerapimainID)||0) > 0) {
			vDataviewArr	= vDataviewArr.filter(vItems =>
				parseInt(vItems.id_terapi||"0") === parseInt(uTerapimainID))
		}
		vDataviewArr	= vDataviewArr.slice(vMin,(vMax));

		//console.log("(Terapicabang - contentMain) vDataviewArr = "+JSON.stringify(vDataviewArr));

		const vColcaptionmd 	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") ? 4 : 10;
		const vColcaptionlg 	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") ? 6 : 10;

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={40} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md={vColcaptionmd} lg={vColcaptionlg} className="text-start">{uBahasaObj.caption_namaterapi||"Nama Terapi"}</CCol>
			{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") && (
			<>
				<CCol md="3" lg="2" className="">Biaya Bahan (Rp)</CCol>
				<CCol md="3" lg="2" className="">Biaya Jasa (Rp)</CCol>
			</>
			)}
			<CCol md="2" className="">Total Tarif (Rp)</CCol>
			</CRow>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,terapi_caption,biaya_bahan,biaya_jasa,
				init_process,init_fieldedit
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (terapi_caption||"").toUpperCase();

			const vTerapicaption	= vKeyword==="" ? (terapi_caption||"") 
				: (terapi_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md={vColcaptionmd} lg={vColcaptionlg} className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<span className="text-capitalize text-info">{UFunc.renderHTML(vTerapicaption)}</span>
						
						{(uIsHapusallow) && (
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm"
							className="p-0" 
							color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle me-2" height={25}/>
								Edit Data Tarif
							</CDropdownItem>
						</CDropdownMenu>
						</CDropdown>
						)}
					</div>
					<div className="d-none">{uTokenObj.userhak}</div>
				</CCol>

				{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") && (
				<>
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">Biaya Bahan</CCol>
				<CCol xs="6" md="3" lg="2" className="px-1">
					{((init_fieldedit||"")==="" || (init_fieldedit||"")!=="BIAYABAHAN") && (
					<CInputGroup size="sm" className="justify-content-end align-items-center">
					{UFunc.formatAngka(biaya_bahan)}

					{(uIsHapusallow) && (
						<CInputGroupText className="bg-transparent border-0 p-0 px-1 d-none d-md-block">
						<CTooltip content={"--Edit B.Bahan: "+vCaptionUpper}>
						<CButton size="sm" className="bg-transparent border-0 p-0 m-0 "
							onClick={()=>hdlKlikInitedit(id,"BIAYABAHAN")}
							id={"btnbbahanedit"+id}>
							<CIcon className="classikonedit classikonbox m-0" height={25}/>
						</CButton>
						</CTooltip>
						</CInputGroupText>
					)}
					</CInputGroup>
					)}

					{(init_process||false)===false && (init_fieldedit||"")==="BIAYABAHAN" && (
					<CInputGroup size="sm">
						<NumberFormat 
							value={uInpbiayabahanedit}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInpbiayabahanedit(values.value)}
							renderText={values => (
								<CFormInput size="sm"
									value={values}
									className="bg-white text-end"
									onFocus={(e)=>e.target.select()}
									onChange={(e)=>setInpbiayabahanedit(e.target.value)}
									onKeyDown={(e)=>hdlKDownedit(id,"BIAYABAHAN",e)}
								id={"inpbiayabahan"+id}/>
							)} 
							prefix={'Rp'}/>
						<CInputGroupText className="p-0">
						<CButton size="sm" className="p-1" 
							color="dark"
							onClick={()=>hdlKlikeditOK(id,"BIAYABAHAN")}
							id={"btnbbahanok"+id}>
							<CIcon icon="cilCheck" className="text-white"/>
						</CButton>
						</CInputGroupText>
					</CInputGroup>
					)}

					{(init_process||false)===true && (init_fieldedit||"")==="BIAYABAHAN" && (
						<div align="center">
						<CSpinner color="warning" size="sm"/>
						</div>
					)}
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">Biaya Jasa</CCol>
				<CCol xs="6" md="3" lg="2" className="px-1">
					{((init_fieldedit||"")==="" || (init_fieldedit||"")!=="BIAYAJASA") && (
					<CInputGroup size="sm" className="justify-content-end align-items-center">
					{UFunc.formatAngka(biaya_jasa)}
					{(uIsHapusallow) && (
					<>	
						<CInputGroupText className="bg-transparent border-0 p-0 px-1 d-none d-md-block">
						<CTooltip content={"--Edit B.Jasa: "+vCaptionUpper}>
						<CButton size="sm" className="bg-transparent border-0 p-0 m-0"
							onClick={()=>hdlKlikInitedit(id,"BIAYAJASA")}
							id={"btnbjasa"+id}>
							<CIcon className="classikonedit classikonbox m-0" height={25}/>
						</CButton>
						</CTooltip>
						</CInputGroupText>
					</>	
					)}
					</CInputGroup>
					)}

					{((init_process||false)===false && (init_fieldedit||"")==="BIAYAJASA") && (
					<CInputGroup size="sm">
						<NumberFormat 
							value={uInpbiayajasedit}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInpbiayajasaedit(values.value)}
							renderText={values => (
								<CFormInput size="sm"
									value={values}
									className="bg-white text-end"
									onFocus={(e)=>e.target.select()}
									onChange={(e)=>setInpbiayajasaedit(e.target.value)}
									onKeyDown={(e)=>hdlKDownedit(id,"BIAYAJASA",e)}
								id={"inpbiayajasa"+id}/>
							)} 
							prefix={'Rp'}/>
						<CInputGroupText className="p-0">
						<CButton size="sm" className="p-1" 
							color="dark"
							onClick={()=>hdlKlikeditOK(id,"BIAYAJASA")}
							id={"btnbjasaok"+id}>
							<CIcon icon="cilCheck" className="text-white"/>
						</CButton>
						</CInputGroupText>
					</CInputGroup>
					)}
					
					{(init_process||false)===true && (init_fieldedit||"")==="BIAYAJASA" && (
						<div align="center">
						<CSpinner color="warning" size="sm"/>
						</div>
					)}
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">Total Tarif</CCol>
				<CCol xs="6" md="2" className="px-1 fw-bolder text-success">
					{UFunc.formatAngka(parseFloat(biaya_jasa) + parseFloat(biaya_bahan))}
				</CCol>

				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!isShowForm) return (<></>)//>

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
		<CCol xs="12" className="text-center">
			<span className="classfontoswald">&middot;{uInpcaptionterapi}&middot;</span>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="6" className="text-md-end">Biaya Bahan</CCol>
		<CCol xs="6" className="">
			<NumberFormat 
				value={uInpbiayabahanedit}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => setInpbiayabahanedit(values.value)}
				renderText={values => (
					<CFormInput size="sm"
						value={values}
						className="bg-white text-end"
						onFocus={(e)=>e.target.select()}
						onChange={(e)=>setInpbiayabahanedit(e.target.value)}
					id={"inpbiayabahan"}/>
				)} 
			prefix=""/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="6" className="text-md-end">Biaya Jasa</CCol>
		<CCol xs="6" className="">
			<NumberFormat 
				value={uInpbiayajasedit}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => setInpbiayajasaedit(values.value)}
				renderText={values => (
					<CFormInput size="sm"
						value={values}
						className="bg-white text-end"
						onFocus={(e)=>e.target.select()}
						onChange={(e)=>setInpbiayajasaedit(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id={"inpbiayajasa"}/>
				)} 
			prefix={'Rp'}/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="6" className="text-md-end">Total Tarif</CCol>
		<CCol xs="6" className="text-end text-success">
			{UFunc.formatAngka(parseFloat(uInpbiayabahanedit) + parseFloat(uInpbiayajasedit))}
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentTerapimain = () => {
		if(uTerapimainArr.length <= 0) return ""

		return (
		<>
		{uTerapimainArr.map((vItems,vKeys)=>{
			const { nama_terapi,id } = vItems

			return (
				<option value={id} key={vKeys}>{(nama_terapi||"").toUpperCase()}</option>
			)
		})}
		</>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Terapicabang - apiLoaddata) uFirstload = "+uFirstload)
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 			= uKeywordObj.kwd_terapicabang || "";
		const vCabangIDselect 	= uCabangIDselected;
		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload===true ? "YA" : "TIDAK",
			send_keyword : vKeyword,
			send_cabangid: vCabangIDselect,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapicabang/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				//console.log("(Terapicabang - apiLoaddata) send_firstload = "+output_string.send_firstload)

				setTerapimainArr(JSON.parse(output_string.dataterapi || JSON.stringify(uTerapimainArr)));
				if(uFirstload) setFirstload(false);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapicabang - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Terapicabang - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesharga = (_IDTABEL,_FIELDEDIT) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;
		_FIELDEDIT = (_FIELDEDIT||"").trim();

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		uDatamainArr[vIdxObj].init_process = true;
		let vValue	= parseFloat(uInpbiayabahanedit)||0;
		let vValuebefore = parseFloat(uDatamainArr[vIdxObj].biaya_bahan||0);
		if(_FIELDEDIT === "BIAYAJASA") {
			vValue = parseFloat(uInpbiayajasedit)||0;
			vValuebefore = parseFloat(uDatamainArr[vIdxObj].biaya_jasa||0);
		}

		if(vValue === vValuebefore) {
			initTabelawal(); return
		}
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(Terapicabang - apiProsesharga) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : uCabangIDselected,
			send_terapisubid : _IDTABEL,
			send_value : vValue,
			send_field : _FIELDEDIT,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapicabang/pr_simpanharga";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].init_process = false;
			if(output_string.sukses) {
				if(_FIELDEDIT==="BIAYABAHAN")
					uDatamainArr[vIdxObj].biaya_bahan = vValue;
				else if(_FIELDEDIT==="BIAYAJASA")
					uDatamainArr[vIdxObj].biaya_jasa = vValue;

				initTabelawal()
			} else {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Terapicabang - apiProsesharga) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].init_process = false;
			setEfekview2(!uEfekview2);
			console.log("(Terapicabang - apiProsesharga) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vElsbutton= document.getElementById("btnDialogSimpan");

		vElsbutton && (vElsbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Terapicabang - apiProsessimpan) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapisubid : _IDTABEL,
			send_cabangid : uCabangIDselected,
			send_biayabahan : parseFloat(uInpbiayabahanedit)||0,
			send_biayajasa : parseFloat(uInpbiayajasedit)||0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapicabang/pr_simpanmobile";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElsbutton && (vElsbutton.disabled = false);
			if(output_string.sukses) {
				uDatamainArr[vIdxObj].biaya_bahan = parseFloat(uInpbiayabahanedit);
				uDatamainArr[vIdxObj].biaya_jasa = parseFloat(uInpbiayajasedit);
				hdlToggledlg();
			} else {
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Terapicabang - apiProsessimpan) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			vElsbutton && (vElsbutton.disabled = false);
			setLoading(false);
			console.log("(Terapicabang - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
			setShowForm(false);
			uDispatch({type: "set", gInitHeaderAction: {}});

    		setDatamainArr([]);
			setTerapimainArr([]); setFirstload(true);
    		localStorage.removeItem("cabangidselect")
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_terapicabang || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));

		const vEljmldatamobile = document.getElementById("idjmldatamobile");
		vEljmldatamobile && (vEljmldatamobile.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{
		setPageActive(1);

		let vJmldata;
		if(parseInt(uTerapimainID) > 0) {
			vJmldata = (uDatamainArr.filter(vItems => 
				parseInt(vItems.id_terapi||"0") === parseInt(uTerapimainID))||[]).length;
		} else {
			vJmldata = uJmlData;
		}
		const vJmlHal	= Math.ceil(vJmldata / uMaxData);
		setJmlHal(vJmlHal);//-*/

		setEfekview(!uEfekview)
	},[uTerapimainID])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	/*React.useEffect(()=>{
		if(!uHandelTambah) return;

		setHeaderdlg((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;
		setShowForm(true)
	},[uHandelTambah])
	React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		if(!isShowForm) { initTabelawal(); return; }

		//console.log("("+cComponentName+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpbiayabahan");
			vElFocus && vElFocus.focus();
		},200);
	},[isShowForm])

	//console.log("Terapicabang - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center px-1 px-md-3">
		<div>
			<CInputGroup size="sm" className="align-items-center">
			<CFormSelect size="sm"
				style={{maxWidth:325}}
				onChange={(e)=>setTerapimainID(e.target.value)}
				value={uTerapimainID}
				id="inpterapimain">
				<option value="0">-- SEMUA TERAPI --</option>
				{contentTerapimain()}
			</CFormSelect>

			<span className="mx-1">&middot;</span>

			<span className="classfontsmaller">
			<small>
			(<b>{(()=>{
				if(parseInt(uTerapimainID) <= 0) return uDatamainArr.length;

				return (uDatamainArr.filter(vItems => 
					parseInt(vItems.id_terapi||"0") === parseInt(uTerapimainID))||[]).length;
			})()}</b>)
			</small>
			</span>
			</CInputGroup>
		</div>

		<span className="text-muted classfontsmaller ps-1">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(()=>{
			let vJmldata;
			if(parseInt(uTerapimainID) > 0) {
				vJmldata = (uDatamainArr.filter(vItems => 
					parseInt(vItems.id_terapi||"0") === parseInt(uTerapimainID))||[]).length;
			} else {
				vJmldata = uJmlData;
			}
			
			if(vJmldata > uMaxData)
			return (
			<CCardFooter>
				<MyPagination 
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i)=>setPageActive(i)}
					align="start"/>
			</CCardFooter>
			)	
		})()}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={isShowForm} 
			dialogHeader={uHeaderdlg}
			toggle={hdlToggledlg}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default Terapicabang