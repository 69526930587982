import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CButton,
	CImage,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CSpinner,
	CLink,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	MyDialogform,
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import {
	UFunc,cAngkaTgl,cBulanPendek,cBulanPanjang 
} from '../helpers/functions'

const pjson 	= require('../../package.json')
const Lapkasir 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uSettingsistemObj			= useSelector(state => state.gSystemsetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSelisihrangetanggal 		= useSelector(state => state.gSelisihrangetanggal);
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 				= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});
	//const uFilterObj		  		= useSelector(state => state.gFilterObj) || {};

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatakasirArr,setDatakasirArr]= React.useState([]); 
	const [uDatapaymentArr,setDatapaymentArr]= React.useState([]); 
	const [uDatasumArr,setDatasumObj]	= React.useState({});
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--*/

	//--FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	//--END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uHTMLstylenota		= useSelector(state => state.gHTMLstyleNota);
	const uLebarKertas			= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri			= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize				= parseInt(uSettingcetakObj.fontsize)||12;
	const uViewLogo				= uSettingcetakObj.view_image_nota ||"TIDAK";
	const uFontstyle			= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}
		/*vElfocus	= document.getElementById("inpjenis");
		if((uFormdataObj.jenis||"").trim() !== "TANGGAL"
			&& (uFormdataObj.jenis||"").trim() !== "BULAN") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Laporan Tidak Valid !");
			return
		}

		let vTanggal;
		if((uFormdataObj.jenis||"").trim() === "TANGGAL") {
			vElfocus	= document.getElementById("inptgl");
			vTanggal  	= uFormdataObj.thn+"-"+uFormdataObj.bln+"-"+uFormdataObj.tgl; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tanggal Laporan Tidak Valid ! "); return
			}
		} else {
			vElfocus	= document.getElementById("inpbulanid");
			vTanggal  	= uFormdataObj.thn+"-"+uFormdataObj.bln+"-01"; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Bulan-Tahun Laporan Tidak Valid !"); return
			}
		}//-*/
		vElfocus	= document.getElementById("inptanggalawal");
		if((uFormdataObj.tgl_awal||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Awal Tidak Valid (1) ! "); return
		}
		const vTanggalawalDT	= UFunc.toDate(uFormdataObj.tgl_awal);
		if(UFunc.DateIsBeyond(vTanggalawalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Awal Laporan Melewati Tanggal SAAT INI ! "); return
		}
		vElfocus	= document.getElementById("inptanggalakhir");
		if((uFormdataObj.tgl_akhir||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Akhir Tidak Valid (1) ! "); return
		}
		const vTanggalnowDT 	= new Date();
		const vTanggalakhirDT	= UFunc.toDate(uFormdataObj.tgl_akhir);
		const vTanggalBatasDT	= new Date(vTanggalnowDT.getFullYear(),vTanggalnowDT.getMonth()+1,0);

		//alert(UFunc.SelisihHari(UFunc.DbDate(vTanggalBatasDT),UFunc.DbDate(vTanggalakhirDT))); return;
		if(UFunc.SelisihHari(UFunc.DbDate(vTanggalBatasDT),UFunc.DbDate(vTanggalakhirDT)) > 0) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Akhir Laporan Melewati Tanggal BULAN INI ! "); return
		}

		if(UFunc.SelisihHari(UFunc.DbDate(vTanggalawalDT),UFunc.DbDate(vTanggalakhirDT)) > uSelisihrangetanggal) {
			vElfocus && vElfocus.focus();
			showToast("Maksimal Selisih Range Tanggal adalah "+uSelisihrangetanggal+" hari.."+
				"<br/>Mohon Diperbaiki !"+
				""); return
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoaddata();
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetaklaporan();
		if(vContent === "") return
		//console.log("(Lapkasir - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	/*const hdlKlikCetakcopy = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper	= (uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase()

		initLoadnota(_IDTABEL);
	}//-*/
	/*const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper	= (uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase()

		if(await(Konfirm(
			"Hapus Nota <b>"+vCaptionupper+"</b>.."+
			"<br/>Proses HAPUS Tidak dapat DIULANG, dan Data Nota yang Dihapus Tidak Dapat Dikembalikan."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin Akan Menghapus ?</b>"+
			""))) apiProseshapus(_IDTABEL);
	}//-*/
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vKasirIdx		= UFunc.getObjectIndeks(uDatakasirArr,uFilterObj.kasir_id);
		const vKasirtext	= vKasirIdx >= 0 ? uDatakasirArr[vKasirIdx].nama_kasir : "";

		const vPaymentidx	 = UFunc.getObjectIndeks(uDatapaymentArr,uFilterObj.payment_id);
		const vPaymentcaption= (uDatapaymentArr[vPaymentidx]||{}).nama_payment;

		const vKasirID		= parseInt((uFilterObj||{}).kasir_id)||0;
		const vTanggalawalDT= UFunc.toDate(uFilterObj.tgl_awal);
		const vTanggalakhirDT= UFunc.toDate(uFilterObj.tgl_akhir);

		const vTglawallabel = vTanggalawalDT.getFullYear()+UFunc.leadZero(vTanggalawalDT.getMonth()+1)+UFunc.leadZero(vTanggalawalDT.getDate());
		const vTglakhirlabel= vTanggalakhirDT.getFullYear()+UFunc.leadZero(vTanggalakhirDT.getMonth()+1)+UFunc.leadZero(vTanggalakhirDT.getDate());

		let vColspan		= 17;
		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			no_nota: "",
			tgl_bayar: "",
			kasir_caption: "",
			pasien_caption: "",
			dokter_caption: "",
			item_caption: "",
			item_qty: "",
			item_harga: "",
			item_diskon: "",
			item_nilai: "",
			nilai_transaksi: "",
			nilai_terbayar: "",
			nilai_tagihan: "",
			payment_caption: "",
			payment_nilai: "",
			total_bayar: "",
		}

		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		let vItemcount;
		let vPaymentcount;
		let vPaymentnama 	= "";
		let vPaymentnilai 	= "";
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				id,kasir_caption,pasien_caption,pasien_telp,no_nota,dokter_caption,
				payment_arr,items_arr,nilai_transaksi,nilai_terbayar,nilai_bayar,
				tgl_bayar,nilai_tagihan,
			} = vItems;

			vItemcount		= 0;
			vPaymentcount 	= 0;
			
			vRowcount++;
			vColposwidth = (pasien_caption||"").length > vColposwidth 
				? (pasien_caption||"").length : vColposwidth; 

			const vItemcaption	= ((items_arr||[])[0]||{}).caption_terapi || "Undf Terapi";
			const vItemqty		= parseInt(((items_arr||[])[0]||{}).qty || "1");
			const vItemharga	= parseFloat(((items_arr||[])[0]||{}).harga_terapi || "0");
			const vItemdiskon	= parseFloat(((items_arr||[])[0]||{}).diskon_terapi || "0");
			const vItemnilai	= vItemqty * (vItemharga - vItemdiskon);
			vItemcount++;

			vPaymentnama		= ((payment_arr||[])[0]||{}).payment_caption || "";
			vPaymentnilai		= parseFloat(((payment_arr||[])[0]||{}).payment_bayar || "0");
			vPaymentcount++;

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				no_nota: {v:(no_nota||"-"),t:"s",s:{wrapText:true}},
				tgl_bayar: {v:UFunc.toDate(tgl_bayar),t:"d"},
				kasir_caption: {v:UFunc.Usercaptionfilter(kasir_caption||"Undf"),t:"s"},
				pasien_caption: {v:(pasien_caption||"Undf Pasien"),t:"s"},
				dokter_caption: {v:'DRG.'+(dokter_caption||"Undf"),t:"s",s:{wrapText:true}},
				item_caption: {v:vItemcaption,t:"s",s:{wrapText:true}},
				item_qty: { v:vItemqty, t:"n", s:{numFmt: "#,##0"} },
				item_harga: { v:vItemharga, t:"n", s:{numFmt: "#,##0"} },
				item_diskon: { v:vItemdiskon, t:"n", s:{numFmt: "#,##0"} },
				item_nilai: { v:vItemnilai, t:"n", s:{numFmt: "#,##0"} },
				nilai_transaksi: { v:parseFloat(nilai_transaksi)||0, t:"n", s:{numFmt: "#,##0"} },
				nilai_terbayar: { v:parseFloat(nilai_terbayar)||0, t:"n", s:{numFmt: "#,##0"} },
				nilai_tagihan: { v:parseFloat(nilai_tagihan)||0, t:"n", s:{numFmt: "#,##0"} },
				payment_caption: {v:vPaymentnama,t:"s",s:{wrapText:true}},
				payment_nilai: { v:vPaymentnilai, t:"n", s:{numFmt: "#,##0"} },
				total_bayar: { v:parseFloat(nilai_bayar)||0, t:"n", s:{numFmt: "#,##0"} },
			} 
			vDatasetsArr.push(vTmpObj);

			console.log("(Lapkasir - hdlKlikExcel) vItemcount = "+vItemcount);
			console.log("(Lapkasir - hdlKlikExcel) (items_arr||[]).length = "+(items_arr||[]).length);
			if(vItemcount < (items_arr||[]).length)
				(items_arr||[]).filter((_,vKeys)=>vKeys > (vItemcount-1)).map((vItems,vKeys)=>{
					vRowcount++;

					const vItemcaption	= vItems.caption_terapi || "Undf Terapi";
					const vItemqty		= parseInt(vItems.qty) || 1;
					const vItemharga	= parseFloat(vItems.harga_terapi) || 0;
					const vItemdiskon	= parseFloat(vItems.diskon_terapi) || 0;
					const vItemnilai	= vItemqty * (vItemharga - vItemdiskon);

					vPaymentnama 	= "";
					vPaymentnilai 	= "";
					if(vPaymentcount < (payment_arr||[]).length) {
						vPaymentnama	= ((payment_arr||[])[vKeys+1]||{}).payment_caption || "";
						vPaymentnilai	= (((payment_arr||[])[vKeys+1]||{}).payment_bayar) || "0";
						vPaymentcount++;
					}

					vTmpObj	= {
						no : "",
						no_nota: "",
						tgl_bayar: "",
						kasir_caption: "",
						pasien_caption: "",
						dokter_caption: "",
						item_caption: {v:vItemcaption,t:"s",s:{wrapText:true}},
						item_qty: { v:vItemqty, t:"n", s:{numFmt: "#,##0"} },
						item_harga: { v:vItemharga, t:"n", s:{numFmt: "#,##0"} },
						item_diskon: { v:vItemdiskon, t:"n", s:{numFmt: "#,##0"} },
						item_nilai: { v:vItemnilai, t:"n", s:{numFmt: "#,##0"} },
						nilai_transaksi: "",
						nilai_terbayar: "",
						nilai_tagihan: "",
						payment_caption: {v:vPaymentnama,t:"s",s:{wrapText:true}},
						payment_nilai: { v:vPaymentnilai, t:"n", s:{numFmt: "#,##0"} },
						total_bayar: "",
					} 

					vItemcount++;
					vDatasetsArr.push(vTmpObj);
				});

			if(vPaymentcount < (payment_arr||[]).length) 
				(payment_arr||[]).filter((_,vKeys)=>vKeys > (vPaymentcount-1)).map((vItems,vKeys)=>{
					vRowcount++;

					vPaymentnama	= vItems.payment_caption || "";
					vPaymentnilai	= parseFloat(vItems.payment_bayar) || 0;
					vPaymentcount++;

					vTmpObj	= {
						no : "",
						no_nota: "",
						tgl_bayar: "",
						kasir_caption: "",
						pasien_caption: "",
						dokter_caption: "",
						item_caption: "",
						item_qty: "",
						item_harga: "",
						item_diskon: "",
						item_nilai: "",
						nilai_transaksi: "",
						nilai_terbayar: "",
						nilai_tagihan: "",
						payment_caption: {v:vPaymentnama,t:"s",s:{wrapText:true}},
						payment_nilai: { v:vPaymentnilai, t:"n", s:{numFmt: "#,##0"} },
						total_bayar: "",
					} 

					vDatasetsArr.push(vTmpObj);
				});
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			no_nota: "",
			tgl_bayar: "",
			kasir_caption: "",
			pasien_caption: "",
			dokter_caption: "",
			item_caption: "",
			item_qty: "",
			item_harga: "",
			item_diskon: "",
			item_nilai: "",
			nilai_transaksi: "",
			nilai_terbayar: "",
			nilai_tagihan: "",
			payment_caption: "",
			payment_nilai: "",
			total_bayar: { v:parseFloat(uDatasumArr.nilai_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		//console.log(vRowcount+" => "+JSON.stringify(vTmpObj));
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wpx:80},
			{wpx:80},
			{wpx:90},
			{wpx:200},
			{wpx:200},
			{wpx:250},
			{wpx:50},
			{wpx:90},
			{wpx:90},
			{wpx:90},
			{wpx:90},
			{wpx:100},
			{wpx:100},
			{wpx:150},
			{wpx:90},
			{wpx:120},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPKASIR_"+
			vTglawallabel+"_"+vTglakhirlabel+
		"";
		const vSheetlabel = vTglawallabel;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+UFunc.TglAngka(uFilterObj.tgl_awal)+" s.d. "+UFunc.TglAngka(uFilterObj.tgl_akhir)+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
			((parseInt(uFilterObj.kasir_id)||0) > 0 ? "\r\nKasir: "+vKasirtext : "")+
			((parseInt(uFilterObj.payment_id)||0) > 0 ? "\r\nMetode: "+vPaymentcaption : "")+
		"";

		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"No.Nota",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Tgl Bayar",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Kasir",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Dokter",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Nama Terapi",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Qty",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"H.Terapi",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Diskon",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Terapi",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Transaksi (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Terbayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Tagihan (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Metode Bayar",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Total Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setJmlData(0);setPageActive(1);
		setCaptionObj({}); setDatamainArr([]);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//--END INIT--/

	//--CONTENT--/
	//console.log("uDatakasirArr => "+JSON.stringify(uDatakasirArr))
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsistemObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vKasirviewArr		=
			uDatakasirArr.filter(vItems=>(vItems.cabang_arr || []).includes(vCabangID.toString()))

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
			<CCol xs="3" md="4">Cabang</CCol>
			<CCol className="text-end">
			<CFormSelect size="sm"
				value={uFormdataObj.cabang_id||0}
				onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
				id="inpcabangid">
				{vCabangviewArr.map((vItems,vKeys)=>{
					const {
						id,cabang_nama
					} = vItems

					return (
					<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
					)
				})}
			</CFormSelect>
			</CCol>
		</CRow>
		)}

		<CRow className="my-2 d-none">
		<CCol xs="3" md="4">Jenis Laporan</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.jenis}
			onChange={(e)=>{uFormdataObj.jenis = e.target.value; setEfekview(!uEfekview)}}
			id="inpjenis">
			<option value="TANGGAL">per TANGGAL</option>
			<option value="BULAN">per BULAN</option>
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">Tanggal</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_awal}
				onChange={(e)=>{uFormdataObj.tgl_awal=e.target.value;setEfekview(!uEfekview)}}
				id="inptanggalawal"/>
			<CInputGroupText>s.d.</CInputGroupText>
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_akhir}
				onChange={(e)=>{uFormdataObj.tgl_akhir=e.target.value;setEfekview(!uEfekview)}}
				id="inptanggalakhir"/>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">Metode</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm" className="py-1"
				value={uFormdataObj.payment_id || ""}
				onChange={(e)=>{uFormdataObj.payment_id = e.target.value; setEfekview(!uEfekview)}}
				id="inpmetode">
				<option value="-1">&middot;&middot; SEMUA METODE &middot;&middot;</option>
				<option value="0">T U N A I</option>
				{uDatapaymentArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>{(vItems.nama_payment||"").toUpperCase()}</option>
					)
				})}
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">Kasir</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uFormdataObj.kasir_id || "0"}
				onChange={(e)=>{uFormdataObj.kasir_id = e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpkasirid">
				<option value="0">&middot;&middot; SEMUA KASIR &middot;&middot;</option>
				{vKasirviewArr.map((vItems,vKeys)=>{
				return (
					<option value={vItems.id} key={vKeys}>
					{(vItems.nama_kasir||"UNDF").toUpperCase()}
					</option>
				)
				})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentLaporan = () => {
		if(!uIsDataloaded) return ""

		if(uJmlData <= 0) return (
			<div className="mx-md-5 p-3 p-md-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 950;

		return (
		<div style={{overflowX:"auto"}} className="" id="iddivtabel">
		<table 
			style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" 
			id="idtabeldata">
		<thead>
			<tr className="text-start align-top classfontsmaller">
			<th width={45} className="px-1 text-center border" style={vStylekolfixed}>No</th>
			
			<th width={130} className="px-1 border" style={{...vStylekolfixed,left:45}}>{uBahasaObj.caption_nonota||"Nota"}</th>
			<th width={100} className="px-1 text-center border">{uBahasaObj.caption_tglbayar || "Tgl Bayar"}</th>
			
			<th width={225} className="px-1 border">{uBahasaObj.word_pasien||"Pasien"}</th>

			<th className="px-1 border">{uBahasaObj.word_keterangan||"Keterangan"}</th>
			
			<th width={125} className="px-1 text-center border">N.Tagihan (Rp)</th>
			<th width={125} className="px-1 text-center border">
				<div className={"me-1"+((uSortedObj.nama||"")==="nilai_bayar" ? " text-danger" : "")}>
					{uBahasaObj.caption_nbayar||"N.Bayar"}
					<CLink className="ms-1 classcursorpointer" style={{color:"inherit"}} onClick={()=>hdlKlikSort("nilai_bayar")}>
						<CIcon icon={((uSortedObj.nama||"")==="nilai_bayar" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
							className="align-middle" height={20}/>
					</CLink>

					<small className="d-block">(Rp)</small>
				</div>

			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,kasir_caption,pasien_caption,pasien_telp,no_nota,dokter_caption,
				items_arr,payment_arr,tgl_bayar,status_bayar,nilai_tagihan,
				nilai_transaksi,nilai_bayar,init_processing,nilai_diskon,
				nilai_terbayar,
			} = vItems;

			vNumber++;
			return (
			<tr id={"idtr"+id} className={"align-top "+(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{(vNumber)}.</td>

				<td className="text-start px-1 border-end" style={{...vStylekolfixed,left:45}}>
					<b className="text-uppercase d-block">{(no_nota||"UNDF")}</b>
					
					<div className="text-uppercase classfontsmaller" style={{color:"Maroon"}}>
						<small>{UFunc.Usercaptionfilter(kasir_caption||"UNDF")}</small>
					</div>
				</td>

				<td  className="text-start">{UFunc.TglAngka(tgl_bayar)}</td>
				
				<td className="text-start px-1">
					<div className="text-capitalize text-info">{pasien_caption||"UNDF"}</div>
					<div className="classfontsmaller">
						<small className="fw-bolder">DRG.{dokter_caption||"UNDF"}</small>
					</div>
				</td>

				<td className="text-start px-1 classfontsmaller">
					<b className="d-block">Item Terapi</b>
					<small>
					<ul className="ps-3 my-0">
					{(items_arr||[]).map((vItemterapi,vKeyterapi)=> {

						return (
						<li key={vKeyterapi}>
						<div className="d-flex justify-content-between align-items-start">
							<div className="fst-italic">{vItemterapi.caption_terapi}</div>
							<div className="text-end" 
								style={{minWidth:"35%",width:((parseFloat(vItemterapi.diskon_terapi)||0) > 0 ? "50%" : "auto")}}>
								(<b>{vItemterapi.qty}</b> UNT
								<span className="mx-1">x</span>
								<b className="text-primary">{UFunc.formatAngka(vItemterapi.harga_terapi)}</b>)

								{(parseFloat(vItemterapi.diskon_terapi)||0) > 0 && (
								<span className="text-danger">
									<span className="mx-1">-</span>
									({UFunc.formatAngka((parseInt(vItemterapi.qty)||1) * (parseFloat(vItemterapi.diskon_terapi)||0))})
								</span>
								)}
							</div>
						</div>
						</li>
						)
					})}
					</ul>
					</small>

					{(parseFloat(nilai_terbayar)||0) > 0 && (
					<>
						<div className="my-1 classborderbottom"/>
						<div className="d-flex justify-content-between align-items-start">
							<b>Terbayar Sebelumnya</b>

							<small><b>{UFunc.formatAngka(nilai_terbayar)}</b></small>
						</div>
					</>
					)}

					{(payment_arr||[]).length > 0 && (
					<>
					<div className="my-1 classborderbottom"/>

					<b className="d-block">Metode Payment</b>

					<small>
					<ol className="ps-3 my-0">
					{(payment_arr||[]).map((vItempayment,vKeypayment)=> {

						return (
						<li key={vKeypayment}>
						<div className="d-flex justify-content-between align-items-start">
							<div className="fst-italic">{vItempayment.payment_caption}</div>
							<b>{UFunc.formatAngka(vItempayment.payment_bayar)}</b>
						</div>
						</li>
						)
					})}
					</ol>
					</small>
					</>
					)}

					{(parseFloat(nilai_diskon)||0) > 0 && (
					<>
						<div className="my-1 classborderbottom"/>
						<div className="d-flex justify-content-between align-items-start">
							<b>Diskon Transaksi</b>

							<small className="text-danger"><b>{UFunc.formatAngka(nilai_diskon)}</b></small>
						</div>
					</>
					)}
				</td>

				<td className="text-end px-1">{UFunc.formatAngka(nilai_tagihan)}</td>

				<td className="px-1 text-end text-success">{UFunc.formatAngka(nilai_bayar)}</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td className="px-1 text-center text-uppercase" colSpan={(isMobile ? 2 : 6)}>
			{uBahasaObj.word_jumlah||"JUMLAH"} 
		</td>

		{isMobile && (<td className="px-1" colSpan={4}/>)}

		<td className="px-1 text-end">
			{UFunc.formatAngka(uDatasumArr.nilai_bayar)}
		</td>
		</tr>
		</tfoot>

		</table>
		</div>
		)//>
	}
	const contentCetaklaporan = () => {
		if(uDatamainArr.length <= 0) return "";
		console.log("(Lapkasir - contentCetaklaporan) uFilterObj => "+JSON.stringify(uFilterObj))

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vKasirIdx		= UFunc.getObjectIndeks(uDatakasirArr,uFilterObj.kasir_id);
		const vKasirtext	= vKasirIdx >= 0 ? uDatakasirArr[vKasirIdx].nama_kasir : "";

		const vPaymentidx	 = UFunc.getObjectIndeks(uDatapaymentArr,uFilterObj.payment_id);
		const vPaymentcaption= (uDatapaymentArr[vPaymentidx]||{}).nama_payment;

		let vColspan		= 6;
		let vHTMLs 			= uHTMLstylelaporan;

		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="`+vColspan+`">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 ? `<div>Cabang: `+vCabangtext+`</div>` : ``)+`
			`+(parseInt(uFilterObj.kasir_id) > 0 ? "<div>Kasir: <b>"+vKasirtext+"</b></div>" : "")+`
			`+((parseInt(uFilterObj.payment_id)||0) > 0 ? "<div>Metode: <b>"+vPaymentcaption+"</b></div>":"")+`
			</td>
			</tr>

			<tr class="idtabelheader">
				<th width="5%">No</th>
				<th width="15%" align="left">`+(uBahasaObj.caption_nonota||"No.Nota")+`</th>
				<th width="13%" align="left">`+(uBahasaObj.word_tanggal||"Tanggal")+`</th>
				<th align="left">`+(uBahasaObj.word_pasien||"Pasien")+`</th>
				<th width="13%">`+(uBahasaObj.caption_ntagihan||"Tagihan")+`<br/>(Rp)</th>
				<th width="15%">`+(uBahasaObj.caption_nbayar||"N.Bayar")+`<br/>(Rp)</th>
			</tr>
			</thead>

			<tbody>
			`;

		vHTMLs += uDatamainArr.map((vItems,vKeys)=>{
			const {
				id,kasir_caption,pasien_caption,pasien_telp,no_nota,dokter_caption,
				payment_caption,nilai_transaksi,nilai_bayar,nilai_tagihan,
				tgl_bayar,
			} = vItems;

			return (`
				<tr key={vKeys} valign="top">
					<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
					<td align="left" style="font-size:smaller">`+
						`<b>`+(no_nota||"-")+`</b>`+
						((parseInt(uFilterObj.kasir_id)||0) <= 0 ? ` 
						<div>`+UFunc.Usercaptionfilter((kasir_caption||"UNDF"))+`</div>
						` : "")+
					`</td>
					<td align="left" style="font-size:smaller">`+UFunc.TglAngka(tgl_bayar)+`</td>
					<td align="left">`+
						`<div>`+pasien_caption+`</div>
						<small>DRG.`+UFunc.Usercaptionfilter(dokter_caption||"-")+`</small>
					</td>
					<td align="right">`+UFunc.formatAngka(nilai_tagihan)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_bayar)+`</td>
				</tr>
			`);
		}).join(``);

		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="`+(vColspan-1)+`" align="center">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_bayar)+`</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Lapkasir - apiLoadawal) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatakasirArr(JSON.parse(output_string.datakasir||"[]"));
				setDatapaymentArr(JSON.parse(output_string.datapayment||"[]"));
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoadawal) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				setHtml500msg(vMsg);
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapkasir - apiLoadawal) catch-error : "+error);
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddata = () => {
		//_PAGE		= parseInt(_PAGE) || 0;

		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;
		/*
		const vHandelview	= (_PAGE <= 0 || uFirstload==="YA")?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}//-*/

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			//send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			//send_maxdata	: uMaxData,
			send_firstload	: uFirstload,//vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_laporan";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//setJmlData(vLoadArr.length);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				hdlToggleDlgform();
				setDataloaded(true);
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg  = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapkasir - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadawal();

		return () => {
			setDlgformshow(false);
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);setDatakasirArr([]);setDatapaymentArr([]);
			setDataloaded(false)
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uDatamainArr.length <= 0)
			{ setDatadefaultArr([]); return }

		setDatadefaultArr([{init_change:"1"},...uDatamainArr]) 
	},[uDatamainArr])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length)
		if(uDatamainArr.length <= 0)
			{ setDatasumObj({}); return }

		const vSumtotal	= uDatamainArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_bayar||"0"), 0);
		setDatasumObj({nilai_bayar: vSumtotal});
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		/*//--LOAD_PER_PAGE--/
		if(uFirstload==="YA") return;

		setIDselect(0);
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
		//--END LOAD_PER_PAGE--*/

		//--LOAD_ALL_VIEW_PER_PAGE--/
		setIDselect(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setHeaderDlgform("Pilih Filter Laporan")
		setFormdataObj({...uFilterObj, init_change: 1});

		setDlgformshow(true)
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) { apiLoadawal(); }
		else { setDataloaded(false); }
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		vTitelmain 	+= "<b>"+UFunc.TglAngka(uFormdataObj.tgl_awal)+"</b>";
		vTitelmain	+= " s.d. ";
		vTitelmain 	+= "<b>"+UFunc.TglAngka(uFormdataObj.tgl_akhir)+"</b>";

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr.length > 1 
			? (uDatacabangArr[vCabangIdx]||{}).cabang_nama + " &middot "
			: "";

		if(parseInt(uFormdataObj.payment_id||"0") === 0) {
			vTitelsub	+= "<b>METODE TUNAI</b>";
		} else if(parseInt(uFormdataObj.payment_id||"0") > 0) {
			const vArridx = UFunc.getObjectIndeks(uDatapaymentArr,uFormdataObj.payment_id);
			vTitelsub	+= "<b>METODE "+uDatapaymentArr[vArridx].nama_payment+"</b>";
		} else {
			vTitelsub	+= "<b>SEMUA METODE </b>";
		}
		if(parseInt(uFormdataObj.kasir_id) > 0) {
			const vKasirIdx= UFunc.getObjectIndeks(uDatakasirArr,uFormdataObj.kasir_id);
			vTitelsub	+= " &middot; "+uDatakasirArr[vKasirIdx].nama_kasir;
		} else {
			vTitelsub	+= " &middot; [SEMUA KASIR]";
		}

		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Lapkasir - [uIsDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uIsDlgformshow) 
			{ setFormdataObj({}); return }

		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");
		//if(uFormdataObj.jenis === undefined) uFormdataObj.jenis = "TANGGAL";
		if(uFormdataObj.payment_id === undefined)
			uFormdataObj.payment_id = -1;
		if(uFormdataObj.kasir_id === undefined)
			uFormdataObj.kasir_id = "0";

		/*//--UNTUK_LAP_LAIN--/
		const vTanggalnowDT = new Date();
		if(uFormdataObj.tgl_awal === undefined)
			uFormdataObj.tgl_awal = vTanggalnowDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalnowDT.getMonth())+"-26"+
			"";
		if(uFormdataObj.tgl_akhir === undefined)
			uFormdataObj.tgl_akhir = vTanggalnowDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+"-25"+
			"";
		//--END UNTUK_LAP_LAIN--*/
		if(uFormdataObj.tgl_awal === undefined)
			uFormdataObj.tgl_awal = UFunc.DbDate();
		if(uFormdataObj.tgl_akhir === undefined)
			uFormdataObj.tgl_akhir = UFunc.DbDate();

		//console.log("(Lapkasir - [uIsDlgformshow]) uFormdataObj => "+JSON.stringify(uFormdataObj));
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			const vElfocus = document.getElementById("inpcabangid");
			vElfocus && vElfocus.focus();
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
	},[uFormdataObj,
	uFormdataObj.cabang_id,
	//uFormdataObj.jenis,
	uFormdataObj.payment_id,
	uFormdataObj.tgl_awal,
	uFormdataObj.tgl_akhir,
	uFormdataObj.kasir_id,
	])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); setIDselect(0); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		const vNamasort = (uSortedObj.nama||"").toLowerCase();
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]);
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			//const vDefaultsortArr = uDatadefaultArr; 
			//vSortedArr = uDatamainArr.sort((a, b) => -1);
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])
	
	//console.log("(Lapkasir) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg) return (
		<CCard className="classbgcard">
		<CCardHeader className="fw-bolder">Page 500</CCardHeader>
		<CCardBody className="mx-md-5 p-md-5">
		<div className="fst-italic">
		{UFunc.renderHTML(uHtml500msg)}
		</div>
		</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
		<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
		<CCardBody className="mx-lg-5 p-lg-5">
		<div className="mx-md-5 p-md-5 fst-italic classpetunjuk">
		*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
		</div>
		</CCardBody>
		</CCard>
		) : (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between px-1 px-md-3">
		<div>
			<div className="d-none d-md-block">{UFunc.renderHTML(uCaptionObj.title)}</div>
			<div className="d-md-none classfontsmaller">
				<small>{UFunc.renderHTML(uCaptionObj.title)}</small>
			</div>
			<span className="classfontsmaller">
				<small className="text-primary">
				{UFunc.renderHTML(uCaptionObj.title_sub)}
				</small>
			</span>
		</div>

		<div className="d-block">
			<span className="d-none d-md-inline">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikon classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>
				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikon classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
				<span className="me-1">&middot;</span>
			</span>

			<span className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeelapse)}ms</small></span>
			<span className="ms-1 d-md-none">&middot;</span>

			<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikExcel()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
		</div>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentLaporan()}</CCardBody>

		{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
		)}
		</CCard>
		</>
		)}
		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		<div className="d-none">
			{(uSystemsettingObj.url_image_nota||"")!=="" && (
				<img src={uSystemsettingObj.url_image_nota} height="100"/>
			)}
		</div>
		</>
	)
}	

export default Lapkasir