import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CButton,
	CImage,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CSpinner,
	CLink,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	MyDialogform,
	MyDialogview,
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cAngkaTgl,cBulanPendek,cBulanPanjang } from '../helpers/functions'

const pjson 		= require('../../package.json')
const Lapfeedokter	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : (uSettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSelisihrangetanggal 		= useSelector(state => state.gSelisihrangetanggal);
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 				= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});
	//const uFilterObj		  		= useSelector(state => state.gFilterObj) || {};

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatadokterArr,setDatadokterArr]= React.useState([]); 
	const [uDatasumArr,setDatasumObj]	= React.useState({});
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--*/

	//--FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	//--END FORM_VARS--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlToggleDlgform = () => {  }
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}
		vElfocus	= document.getElementById("inpjenis");
		if((uFormdataObj.jenis||"").trim() !== "TAHUN"
			&& (uFormdataObj.jenis||"").trim() !== "RANGETANGGAL") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Periode Laporan Tidak Valid !");
			return
		}

		let vTanggal;
		if((uFormdataObj.jenis||"").trim() === "TAHUN") {
			vElfocus	= document.getElementById("inptahun");
			vTanggal  	= uFormdataObj.thn+"-01-01"; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tahun Laporan Tidak Valid ! "); return
			}
		} else {
			vElfocus	= document.getElementById("inptglawal");
			vTanggal  	= uFormdataObj.tgl_awal;
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tanggal AWAL Laporan Tidak Valid !"); return
			}
			const vTanggalawalDT	= UFunc.toDate(uFormdataObj.tgl_awal);
			if(UFunc.DateIsBeyond(vTanggalawalDT)) {
				vElfocus && vElfocus.focus();
				showToast("Tanggal Awal Laporan Melewati Tanggal SAAT INI ! "); return
			}

			vElfocus	= document.getElementById("inptglakhir");
			vTanggal  	= uFormdataObj.tgl_akhir;
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tanggal AKHIR Laporan Tidak Valid !"); return
			}
			const vTanggalnowDT 	= new Date();
			const vTanggalakhirDT	= UFunc.toDate(uFormdataObj.tgl_akhir);
			const vTanggalBatasDT	= new Date(vTanggalnowDT.getFullYear(),vTanggalnowDT.getMonth()+1,0);

			//alert(UFunc.SelisihHari(UFunc.DbDate(vTanggalBatasDT),UFunc.DbDate(vTanggalakhirDT))); return;
			if(UFunc.SelisihHari(UFunc.DbDate(vTanggalBatasDT),UFunc.DbDate(vTanggalakhirDT)) > 0) {
				vElfocus && vElfocus.focus();
				showToast("Tanggal Akhir Laporan Melewati Tanggal BULAN INI ! "); return
			}

			if(UFunc.SelisihHari(UFunc.DbDate(vTanggalawalDT),UFunc.DbDate(vTanggalakhirDT)) > uSelisihrangetanggal) {
				vElfocus && vElfocus.focus();
				showToast("Maksimal Selisih Range Tanggal adalah "+uSelisihrangetanggal+" hari.."+
					"<br/>Mohon Diperbaiki !"+
					""); return
			}
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoaddata();
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikDetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;

		//setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vRowidx);

		const vCaptionupper = UFunc.TglAngka(uDatamainArr[vArridx].tgl_lunas)+"  ("+
			(uDatamainArr[vArridx].pasien_caption||"Undf").toUpperCase()+")";

		uDlgviewObj.dlg_headers = "Detil Transaksi: "+vCaptionupper; 
		if((uDatamainArr[vArridx].items_arr||[]).length > 0) {
			uDlgviewObj.data_obj	= uDatamainArr[vArridx];
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetak();
		if(vContent === "") return
		//console.log("(Lapfeedokter - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	const hdlKlikExcel = () => {
		if(uDatamainArr.length <= 0) return;

		/*if(uFilterObj.jenis === "TAHUN") {
			if(parseInt(uFilterObj.dokter_id||"0") > 0)
				return excelTahundokter();

			return excelTahunall();
		}//-*/
		if(uFilterObj.jenis === "RANGETANGGAL") {
			if(parseInt(uFilterObj.dokter_id||"0") > 0)
				return excelBulandokter();

			return excelBulanall();
		}
	}
	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setCaptionObj({}); setDatamainArr([]);// setJmlData(0);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const excelBulanall = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		
		const vDokterIdx	= UFunc.getObjectIndeks(uDatadokterArr,uFilterObj.dokter_id);
		const vDoktertext	= "SEMUA DOKTER";

		const vTanggalawalDT = UFunc.toDate(uFilterObj.tgl_awal);
		const vTanggalakhirDT= UFunc.toDate(uFilterObj.tgl_akhir);

		const vColspan		= 4;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			dokter_caption : "",
			nilai_tagihan : "",
			nilai_feedokter : "",
		};
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				dokter_caption,nilai_tagihan,nilai_feedokter
			} = vItems;

			vRowcount++;
			vColposwidth = (dokter_caption||"").length > vColposwidth 
				? (dokter_caption||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				dokter_caption: (dokter_caption||"Undf Pasien"),
				nilai_tagihan: { v:parseFloat(nilai_tagihan), t:"n", s:{numFmt: "#,##0"} },
				nilai_feedokter: { v:parseFloat(nilai_feedokter), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			dokter_caption: "",
			nilai_tagihan: { v:parseFloat(uDatasumArr.nilai_tagihan)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_feedokter: { v:parseFloat(uDatasumArr.nilai_feedokter)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
			{wpx:130},
			{wpx:150},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 3}}
		]

		const vTanggalnowDT	= new Date();
		const vTanggalawallabel = vTanggalawalDT.getFullYear()+
			UFunc.leadZero(vTanggalawalDT.getMonth()+1)+
			UFunc.leadZero(vTanggalawalDT.getDate());
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPFEEDOKTER_"+vTanggalawallabel+
		"";
		const vSheetlabel = vTanggalawallabel;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+UFunc.HariAngka(UFunc.DbDate(vTanggalawalDT))+" "+
			UFunc.HariAngka(UFunc.DbDate(vTanggalakhirDT))+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Dokter",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Pembayaran (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Total Fee (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	const excelBulandokter = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		
		const vDokterIdx	= UFunc.getObjectIndeks(uDatadokterArr,uFilterObj.dokter_id);
		const vDoktertext	= uDatadokterArr[vDokterIdx].nama_dokter;

		const vTanggalawalDT = UFunc.toDate(uFilterObj.tgl_awal);
		const vTanggalakhirDT= UFunc.toDate(uFilterObj.tgl_akhir);

		const vColspan		= 6;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			tgl_periksa : "",
			tgl_lunas : "",
			pasien_caption : "",
			nilai_tagihan : "",
			nilai_feedokter : "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		uDatamainArr.map((vItems,vKeys)=>{
			const {
				tgl_lunas,tgl_periksa,
				pasien_caption,nilai_tagihan,nilai_feedokter
			} = vItems;

			vColposwidth = (pasien_caption||"").length > vColposwidth 
				? (pasien_caption||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				tgl_periksa: { v:(tgl_periksa), t:"d",s:{alignment:{horizontal:"left"}}},
				tgl_lunas: { v:(tgl_lunas), t:"d",s:{alignment:{horizontal:"left"}}},
				pasien_caption: (pasien_caption||"Undf Pasien"),
				nilai_tagihan: { v:parseFloat(nilai_tagihan), t:"n", s:{numFmt: "#,##0"} },
				nilai_feedokter: { v:parseFloat(nilai_feedokter), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_periksa: "",
			tgl_lunas: "",
			pasien_caption: "",
			nilai_tagihan: { v:parseFloat(uDatasumArr.nilai_tagihan)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_feedokter: { v:parseFloat(uDatasumArr.nilai_feedokter)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:50}]
		const wscols	= [
			{wpx:40},
			{wpx:90},
			{wpx:90},
			{wch:vColposwidth},
			{wpx:130},
			{wpx:150},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 3}}
			//{s:{r:vRowcount,c:0},e:{r:vRowcount,c:2}}
		]

		const vTanggalnowDT		= new Date();
		const vTanggalawallabel = vTanggalawalDT.getFullYear()+
			UFunc.leadZero(vTanggalawalDT.getMonth()+1)+
			UFunc.leadZero(vTanggalawalDT.getDate());

		const vNamafile 	= vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPFEEDOKTER_"+vTanggalawallabel+
		"";
		const vSheetlabel = vTanggalawallabel; 

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+UFunc.HariAngka(UFunc.DbDate(vTanggalawalDT))+" "+
			UFunc.HariAngka(UFunc.DbDate(vTanggalakhirDT))+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
			"\r\n"+(vDoktertext).toUpperCase()+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tgl Periksa",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Tgl Lunas",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Nama Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Pembayaran (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Total Fee (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	const excelTahunall = () => {//--TIDAK_DIPAKAI--/
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		const vKolwidth		= Math.round(75/13);

		const vTahun		= parseInt((uFilterObj||{}).thn)||0;

		const vColspan		= 15;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			dokter_caption : "",
		};
		cBulanPendek.map((vItembulan,vKeybulan)=>{
			vTmpObj[vItembulan.toLowerCase()] = "";
		});
		vTmpObj.total = "";
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//console.log("vTmpObj HEADER => "+JSON.stringify(vTmpObj));

		uDatamainArr.map((vItems,vKeys)=>{
			const {
				dokter_caption,id
			} = vItems;

			vColposwidth = (dokter_caption||"").length > vColposwidth 
				? (dokter_caption||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				dokter_caption: (dokter_caption||"Undf Dokter"),
			}
			cBulanPendek.map((vItembulan,vKeybulan)=>{
				vTmpObj[vItembulan.toLowerCase()] = 
				{ v:parseFloat(vItems["nilai_"+UFunc.leadZero(vKeybulan)])||0, t:"n", s:{numFmt: "#,##0" } }
			});
			vTmpObj.total = { v:parseFloat(vItems["nilai_total"])||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } };

			vDatasetsArr.push(vTmpObj);
			vRowcount++;
			//console.log("vTmpObj "+vKeys+" => "+JSON.stringify(vTmpObj));
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			dokter_caption: "",
		};
		const vNolArr		= [0,0,0,0,0,0,0,0,0,0,0,0,0];
		const vTotalArr		= uDatasumArr.nilai_arr || vNolArr;

		cBulanPendek.map((vItembulan,vKeybulan)=>{
			vTmpObj[vItembulan.toLowerCase()] =
				{ v:parseFloat(vTotalArr[vKeybulan])||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } }
		});
		vTmpObj.total = 
			{ v:parseFloat(vTotalArr[vTotalArr.length -1])||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } }
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//console.log("vTmpObj FOOTER => "+JSON.stringify(vTmpObj));
		//--END SUM_NILAI--*/

		const wsrows	= [{hpt:41}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
		];
		cBulanPendek.map((vItembulan)=>{
			wscols.push({wpx:90});
		});
		wscols.push({wpx:110});//-*/
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 14}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPFEEDOKTER_"+
			+vTahun+
		"";
		const vSheetlabel 	= vTahun.toString();
		const ws 			= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode Tahun "+vTahun+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
			//"\r\n"+(vDoktertext).toUpperCase()+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Nama Dokter",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
		];
		cBulanPendek.map((vItembulan)=>{
			vHeaderArr.push({v:vItembulan.toUpperCase(),s:{alignment:{horizontal:"center"},font:{bold:true}}});
		});
		vHeaderArr.push({v:"TOTAL",s:{alignment:{horizontal:"center"},font:{bold:true}} });//-*/

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	const excelTahundokter = () => {//--TIDAK_DIPAKAI--/
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		
		const vDokterIdx	= UFunc.getObjectIndeks(uDatadokterArr,uFilterObj.dokter_id);
		const vDoktertext	= uDatadokterArr[vDokterIdx].nama_dokter;

		const vTahun	= parseInt((uFilterObj||{}).thn)||0;

		const vColspan		= 6;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			bulan_caption : "",
			nilai_tagihan : "",
			nilai_diskon : "",
			nilai_payment : "",
			nilai_feedokter : "",
		};
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				bulan_caption,nilai_tagihan,nilai_diskon,nilai_payment,nilai_feedokter
			} = vItems;

			vRowcount++;
			vColposwidth = (bulan_caption||"").length > vColposwidth 
				? (bulan_caption||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				bulan_caption: (bulan_caption||"Undf"),
				nilai_tagihan: { v:parseFloat(nilai_tagihan), t:"n", s:{numFmt: "#,##0"} },
				nilai_diskon: { v:parseFloat(nilai_diskon), t:"n", s:{numFmt: "#,##0"} },
				nilai_payment: { v:parseFloat(nilai_payment), t:"n", s:{numFmt: "#,##0"} },
				nilai_feedokter: { v:parseFloat(nilai_feedokter), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			bulan_caption: "",
			nilai_tagihan: { v:parseFloat(uDatasumArr.nilai_tagihan)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_diskon: "",
			nilai_payment: "",
			nilai_feedokter: { v:parseFloat(uDatasumArr.nilai_feedokter)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:50}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
			{wpx:130},
			{wpx:100},
			{wpx:100},
			{wpx:150},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 5}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPFEEDOKTER_"+
			vTahun+
		"";
		const vSheetlabel 	= vTahun.toString();
		const ws 			= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode Thn "+vTahun+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
			"\r\n"+vDoktertext+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Bulan",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Tagihan (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Pot.Diskon (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Pot.Payment (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Total Fee (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSystemsettingObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vTahunnow			= (new Date()).getFullYear();
		const vDokterviewArr	= uTokenObj.userhak==="DOKTER"
			? uDatadokterArr.filter(vItems => parseInt(vItems.id) === parseInt(uTokenObj.user_dokterid||"0"))
			: uDatadokterArr.filter(vItems => (vItems.cabang_arr || []).includes(vCabangID.toString()) )

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
		<CCol xs="3" md="4">Cabang</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.cabang_id||0}
			onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
			id="inpcabangid">
			{vCabangviewArr.map((vItems,vKeys)=>{
				const {
					id,cabang_nama
				} = vItems

				return (
				<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2 d-none">
		<CCol xs="3" md="4">Jenis Periode</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.jenis}
			onChange={(e)=>{uFormdataObj.jenis = e.target.value; setEfekview(!uEfekview)}}
			id="inpjenis">
			<option value="RANGETANGGAL">per RANGE TANGGAL</option>
			<option value="TAHUN">per TAHUN</option>
			</CFormSelect>
		</CCol>
		</CRow>

		{(uFormdataObj.jenis||"RANGETANGGAL")==="RANGETANGGAL" ? (
		<CRow className="my-2">
		<CCol xs="3" md="4"><span className="d-none d-md-inline">Range </span>Tanggal</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_awal}
				onChange={(e)=>{uFormdataObj.tgl_awal=e.target.value;setEfekview(!uEfekview)}}
				id="inptglawal"/>
			<CInputGroupText>s.d.</CInputGroupText>
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_akhir}
				onChange={(e)=>{uFormdataObj.tgl_akhir=e.target.value;setEfekview(!uEfekview)}}
				id="inptglakhir"/>
			</CInputGroup>
		</CCol>
		</CRow>
		) : (
		<CRow className="my-2">
		<CCol xs="3" md="4">Tahun</CCol>
		<CCol xs="8" lg="4" className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				value={uFormdataObj.thn || UFunc.leadZero((new Date()).getFullYear())}
				onChange={(e)=>{uFormdataObj.thn = e.target.value; setEfekview(!uEfekview)}}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option key={vIdx} value={(vTahunnow - vIdx)}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="3" md="4">Dokter</CCol>
		<CCol className="text-start">
		{uTokenObj.userhak === "DOKTER" ? (
			<b className="text-primary text-uppercase">{uTokenObj.usercaption}</b>
		) : (
		<CFormSelect size="sm"
			value={uFormdataObj.dokter_id}
			onChange={(e)=>{uFormdataObj.dokter_id = e.target.value; setEfekview(!uEfekview)}}
			onKeyDown={(e)=>UFunc.hdlKeyDownInput(e, "btnDialogSimpan")}
			id="inpdokterid">
			<option value="0">-- SEMUA DOKTER --</option>
			{vDokterviewArr.map((vItems,vKeys)=>{
				const { id,nama_dokter } = vItems

				return (
				<option value={id} key={vKeys} className="text-capitalize">{(nama_dokter||"UNDF")}</option>
				)
			})}
		</CFormSelect>
		)}
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentLaporan = () => {
		if(!uIsDataloaded) return ""

		if(uJmlData <= 0) return (
			<div className="mx-md-5 px-lg-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)//>

		/*if(uFilterObj.jenis === "TAHUN") {
			if(parseInt(uFilterObj.dokter_id||"0") > 0)
				return contentTahundokter();

			return contentTahunall();
		}//-*/

		if(uFilterObj.jenis === "RANGETANGGAL") {
			if(parseInt(uFilterObj.dokter_id||"0") > 0)
				return contentBulandokter();

			return contentBulanall();
		}

		return (<></>)//>
	}
	const contentBulanall = () => {
		if(uFilterObj.jenis !== "RANGETANGGAL") return ""
		if(parseInt(uFilterObj.dokter_id||"") > 0) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 500;

		const vDatamainArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div style={{overflowX:"auto"}} className="" id="iddivtabel">
		<table style={{minWidth:vWidthtabel,width:"70%"}}
			className="table table-borderless table-striped table-hover mx-auto" 
			id="idtabeldata">
		<thead>
			<tr className="align-top text-center classfontsmaller">
			<th width={40} className="px-1 border" style={vStylekolfixed}>No</th>
			<th className="text-start px-1 border" style={{...vStylekolfixed,left:40,minWidth:200}}>{uBahasaObj.word_dokter||"Dokter"}</th>

			<th width="25%" style={{minWidth:100}} className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_tagihan" ? " text-danger" : "")}>
				{uBahasaObj.caption_npembayaran||"N.Pembayaran"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_tagihan")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_tagihan" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width="25%" style={{minWidth:100}} className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_feedokter" ? " text-danger" : "")}>
				{uBahasaObj.caption_feenetto||"Total Fee"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_feedokter")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_feedokter" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDatamainArr.map((vItems,vKeys)=>{
			const {
				dokter_caption,nilai_tagihan,nilai_diskon,nilai_payment,
				nilai_feedokter,
			} = vItems;

			vNumber++;
			return (
			<tr className="align-top text-end"
				key={vKeys}>
			<td className="px-1" style={vStylekolfixed}>{vNumber}.</td>
			
			<td className="px-1 text-start" style={{...vStylekolfixed,left:40}}>{(dokter_caption||"UNDF")}</td>
			<td className="px-1 text-success">{UFunc.formatAngka(nilai_tagihan||"0")}</td>
			<td className="px-1 text-primary fw-bolder">
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "":"text-danger"}>
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "d-none":""}>(</span>
					{UFunc.formatAngka(nilai_feedokter)}
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "d-none":""}>)</span>
				</span>
			</td>
			</tr>
			);
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td colSpan={2} className="px-1 text-center text-uppercase text-dark" style={vStylekolfixed}>{uBahasaObj.word_jumlah||"JUMLAH"}</td>
		<td className="px-1 text-end">{UFunc.formatAngka(Math.abs(uDatasumArr.nilai_tagihan))}</td>
		<td className="px-1 text-end">
			<span className={parseFloat(uDatasumArr.nilai_feedokter||"0")>=0 ? "d-none":""}>(</span>
			{UFunc.formatAngka(Math.abs(uDatasumArr.nilai_feedokter))}
			<span className={parseFloat(uDatasumArr.nilai_feedokter||"0")>=0 ? "d-none":""}>)</span>
		</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)//>
	}
	const contentBulandokter = () => {
		if(uFilterObj.jenis !== "RANGETANGGAL") return ""
		if(parseInt(uFilterObj.dokter_id||"") <= 0) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 950;

		const vDatamainArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div style={{overflowX:"auto"}} className="" id="iddivtabel">
		<table style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={40} className="px-1 border" style={vStylekolfixed}>No</th>

			<th width={150} className="px-1 text-start border" style={{...vStylekolfixed,left:40}}>
				<span className={"me-1"+((uSortedObj.nama||"")==="tgl_lunas_sort" ? " text-danger" : "")}>
				{uBahasaObj.caption_tgllunas||"Tgl Lunas"}
				</span>

				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("tgl_lunas_sort")}>
				<CIcon icon={((uSortedObj.nama||"")==="tgl_lunas_sort" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width={120} className="px-1 text-start border">
				{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}
			</th>

			<th className="px-1 text-start border">{uBahasaObj.caption_namapasien||"Nama Pasien"}</th>
			
			<th width={170} className="px-1 border">N.Pembayaran (Rp)</th>

			<th width={175} className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_feedokter" ? " text-danger" : "")}>
				{uBahasaObj.caption_feenetto||"Total Fee"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_feedokter")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_feedokter" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDatamainArr.map((vItems,vKeys)=>{
			const {
				tgl_lunas,tgl_periksa,id,is_loading,
				pasien_caption,nilai_tagihan,nilai_feedokter,
			} = vItems;

			vNumber++;

			const vCaptionupper = UFunc.TglAngka(tgl_lunas)+"  ("+(pasien_caption||"Undf").toUpperCase()+")";

			return (
			<tr id={"idtr"+id} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}
				key={vKeys}>
			<td className="px-1" style={vStylekolfixed}>{vNumber}.</td>
			
			<td className="px-1 text-start" style={{...vStylekolfixed,left:40}}>
				<div className="d-flex justify-content-between">
					<b>{UFunc.TglAngka(tgl_lunas)}</b>

					{(is_loading||false)===true ? (
						<CSpinner size="sm" className="d-md-none" color="primary"/>
					) : (
					<CLink onClick={()=>hdlKlikDetil(id)} className="classcursorpointer">
					<CIcon icon="cilSearch" className="align-middle classikonbox d-md-none" height={23}/>
					</CLink>
					)}
				</div>
			</td>

			<td className="px-1 text-start">{UFunc.TglAngka(tgl_periksa)}</td>

			<td className="px-1 text-start">
				<div className="d-flex justify-content-between">
					<span>{(pasien_caption||"UNDF")}</span>

					{(is_loading||false)===true ? (
						<CSpinner size="sm" className="d-none d-md-block" color="primary"/>
					) : (
					<CTooltip className="d-none d-lg-block" content={"--Detil Transaksi: "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikDetil(id)} className="classcursorpointer">
					<CIcon icon="cilSearch" className="align-middle classikonbox d-none d-md-inline-block" height={23}/>
					</CLink>
					</CTooltip>
					)}
				</div>
			</td>

			<td className="px-1 text-success">{UFunc.formatAngka(nilai_tagihan||"0")}</td>

			<td className="px-1 text-primary fw-bolder">
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "":"text-danger"}>
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "d-none":""}>(</span>
					{UFunc.formatAngka(nilai_feedokter)}
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "d-none":""}>)</span>
				</span>
			</td>
			</tr>
			);
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td colSpan={2} className="text-center text-uppercase text-dark" style={vStylekolfixed}>{uBahasaObj.word_jumlah||"JUMLAH"}</td>
		<td colSpan={2} className="px-1"/>
		<td className="px-1 text-end">{UFunc.formatAngka(Math.abs(uDatasumArr.nilai_tagihan))}</td>
		<td className="px-1 text-end">
			<big>
				<span className={parseFloat(uDatasumArr.nilai_feedokter||"0")>=0 ? "d-none":""}>(</span>
				{UFunc.formatAngka(Math.abs(uDatasumArr.nilai_feedokter))}
				<span className={parseFloat(uDatasumArr.nilai_feedokter||"0")>=0 ? "d-none":""}>)</span>
			</big>
		</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)//>
	}
	const contentTahunall = () => {//--TIDAK_DIPAKAI--/
		if(uFilterObj.jenis !== "TAHUN") return ""
		if(parseInt(uFilterObj.dokter_id||"0") > 0) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDatamainArr	= uDatamainArr.slice(vMin,(vMax));

		const vKolwidth		= Math.round(83/13);
		const vNolArr		= [0,0,0,0,0,0,0,0,0,0,0,0,0];
		const vTotalArr		= uDatasumArr.nilai_arr || vNolArr;

		const vWidthno		= 40;
		const vWidthFirstkol= 250;
		const vWidthkolnilai= 100;

		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthkolnilai*13)

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}

		return (
		<div style={{overflowX:"auto"}} id="iddivtabel">
		<table
			style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover"
			id="idtabeldata">
		<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={vWidthno} style={vStylekolfixed} className="px-1">No</th>
			<th width={vWidthFirstkol}  className="px-1 text-start" style={{...vStylekolfixed,left:vWidthno}}>{uBahasaObj.word_dokter||"Dokter"}</th>
			{cBulanPendek.map((vItems,vKeys)=>{
				return (<th width={vWidthkolnilai} key={vKeys} className="px-1">{vItems}</th>)
			})}
			<th width={vWidthkolnilai} className="px-1">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_total" ? " text-danger" : "")}>
				{uBahasaObj.word_total||"Total"}
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_total")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_total" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDatamainArr.map((vItems,vKeys)=>{
			const {
				dokter_caption,id
			} = vItems;

			vNumber++;

			return (
			<tr id={"idtr"+vKeys} className="align-top text-end" key={vKeys}>
			<td style={vStylekolfixed} 
				className="px-1">
				<small>{vNumber}.</small>
			</td>
			<td className="px-1 text-start"style={{...vStylekolfixed,left:vWidthno}}>
				<small>{dokter_caption||"UNDF"}</small>
			</td>
			{cBulanPendek.map((vItemnilai,vKeynilai)=>{
				return (
				<td className="px-1">
				<small>{UFunc.formatAngka(vItems["nilai_"+UFunc.leadZero(vKeynilai)])}</small>
				</td>
				)
			})}
			<td className="px-1 text-primary">
				<small>{UFunc.formatAngka(vItems["nilai_total"])}</small>
			</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white text-end">
		<tr>
		<td colSpan={2} className="px-1 text-center bg-primary" style={vStylekolfixed}>JUMLAH</td>
		{vTotalArr.map(vItems=>{
			return (<td className="px-1 classfontsmaller">{UFunc.formatAngka(vItems)}</td>)
		})}
		</tr>
		</tfoot>
		</table>
		</div>
		)//>
	}
	const contentTahundokter = () => {//--TIDAK_DIPAKAI--/
		if(uFilterObj.jenis !== "TAHUN") return (<></>)//>
		if(parseInt(uFilterObj.dokter_id||"0") <= 0) return (<></>)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 900;

		const vDatamainArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div style={{overflowX:"auto"}} className="" id="iddivtabel">
		<table 
			style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={40} className="px-1 border" style={vStylekolfixed}>No</th>
			<th className="text-start border" style={{...vStylekolfixed,left:40}}>{uBahasaObj.word_bulan||"Bulan"}</th>
			<th width={175} className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_tagihan" ? " text-danger" : "")}>
				{uBahasaObj.caption_ntagihan||"N.Tagihan"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_tagihan")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_tagihan" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			<th width={150} className="px-1 border">Pot.Diskon (Rp)</th>
			<th width={150} className="px-1 border">Pot.Payment (Rp)</th>
			<th width={175} className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_feedokter" ? " text-danger" : "")}>
				{uBahasaObj.caption_feenetto||"Total Fee"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_feedokter")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_feedokter" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDatamainArr.map((vItems,vKeys)=>{
			const {
				bulan_caption,nilai_tagihan,nilai_diskon,nilai_payment,
				nilai_feedokter,
			} = vItems;

			vNumber++;
			return (
			<tr key={vKeys} className="align-top text-end">
			<td className="px-1" style={vStylekolfixed}>{vNumber}.</td>
			<td className="px-1 text-start" style={{...vStylekolfixed,left:40}}>{(bulan_caption||"UNDF")}</td>
			<td className="px-1 text-success">{UFunc.formatAngka(nilai_tagihan||"0")}</td>
			<td className="px-1 text-danger">{UFunc.formatAngka(nilai_diskon||"0")}</td>
			<td className="px-1 text-danger">{UFunc.formatAngka(nilai_payment||"0")}</td>
			<td className="px-1">
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "text-primary":"text-danger"}>
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "d-none":""}>(</span>
					{UFunc.formatAngka(nilai_feedokter)}
				<span className={parseFloat(nilai_feedokter||"0")>=0 ? "d-none":""}>)</span>
				</span>
			</td>
			</tr>
			);
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td colSpan={2} className="text-center text-uppercase text-dark" style={vStylekolfixed}>{uBahasaObj.word_jumlah||"JUMLAH"}</td>
		<td className="px-1 text-end">{UFunc.formatAngka(Math.abs(uDatasumArr.nilai_tagihan))}</td>
		<td colSpan={2}/>
		<td className="px-1 text-end">
			<span className={parseFloat(uDatasumArr.nilai_feedokter||"0")>=0 ? "d-none":""}>(</span>
			{UFunc.formatAngka(Math.abs(uDatasumArr.nilai_feedokter))}
			<span className={parseFloat(uDatasumArr.nilai_feedokter||"0")>=0 ? "d-none":""}>)</span>
		</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		const vDataviewObj	= uDlgviewObj.data_obj || {};

		const vJumlahfeebruto	= (vDataviewObj.items_arr||[]).reduce((vSUMs,vItems)=>
			vSUMs + (parseFloat(vItems.nilai_feebruto)||0)
			,0)

		return (
		<>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">
				<small>Data Pasien</small>
			</CCardHeader>

			<CCardBody className="classfontsmaller">
				<div className="d-flex justify-content-between">
				<div>Nama Pasien</div>
				<b>{vDataviewObj.pasien_caption}</b>
				</div>

				<div className="d-flex justify-content-between">
				<div>Anamnase</div>
				<b>{vDataviewObj.anamnase||"-"}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">
				<small>Data Periksa</small>
			</CCardHeader>

			<CCardBody className="classfontsmaller">
				<div className="d-flex justify-content-between">
				<div>Tgl Periksa</div>
				<b>{UFunc.TglAngka(vDataviewObj.tgl_periksa)}</b>
				</div>

				<div className="d-flex justify-content-between">
				<div>Tgl Lunas</div>
				<b>{UFunc.TglAngka(vDataviewObj.tgl_periksa)}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">
				<small>Data Terapi</small>
			</CCardHeader>

			<CCardBody className="classfontsmaller">		
			<table className="table table-borderless table-striped" id="idtabeldetil">
			<thead>
			<tr className="align-top text-center classfontsmaller">
				<th width={35}>No</th>
				<th className="text-start">Nama Terapi</th>
				<th width="25%">N.Perawatan<small className="d-none d-md-inline ms-1">(Rp)</small></th>
				<th width="25%">Fee Terapi<small className="d-none d-md-inline ms-1">(Rp)</small></th>
			</tr>
			</thead>

			<tbody>
			{(vDataviewObj.items_arr||[]).map((vItems,vKeys)=>{
				const {
					caption_terapi,nilai_terapi,nilai_feebruto,
					harga_terapi,qty,diskon_terapi,
					id,
				} = vItems;

				return (
				<tr className="align-top" key={vKeys}>
					<td className="px-1 text-end">{(vKeys+1)}.</td>

					<td className="px-1 text-start">
						<small className="fst-italic">{caption_terapi||""}</small>
						<div>
							<span><b>{qty}</b>UNT</span>

							<span className="mx-1">x</span>
							
							{(parseFloat(diskon_terapi)||0) > 0 ? (
							<>
								(
								<span>{UFunc.formatAngka(harga_terapi)}</span>
								<span className="mx-1">-</span>
								<span className="text-danger">{UFunc.formatAngka(parseFloat(diskon_terapi))}</span>
								)
							</>
							) : (
								<span>{UFunc.formatAngka(harga_terapi)}</span>
							)}
						</div>
					</td>

					<td className="px-1 text-end">{UFunc.formatAngka(nilai_terapi)}</td>

					<td className="px-1 text-end">{UFunc.formatAngka(nilai_feebruto)}</td>
				</tr>
				)
			})}
			</tbody>
			
			<tfoot>
			<tr className="fw-bolder border-top">
				<td className="text-end p-1" colSpan={2}>JUMLAH</td>
				<td className="text-end p-1 border-top">{UFunc.formatAngka(vDataviewObj.nilai_bruto)}</td>
				<td className="text-end p-1 border-top">{UFunc.formatAngka(vJumlahfeebruto)}</td>
			</tr>

			{(parseFloat(vDataviewObj.diskon)||0) > 0 && (
			<tr className="text-danger">
				<td className="text-end p-1" colSpan={2}>Diskon</td>
				<td className="text-end p-1">{UFunc.formatAngka(vDataviewObj.diskon)}</td>
				<td className="text-end p-1">{UFunc.formatAngka((vDataviewObj.diskon/2))}</td>
			</tr>
			)}

			<tr className="text-danger">
				<td className="text-end p-1" colSpan={2}>Biaya Payment</td>
				<td className="text-end p-1">{UFunc.formatAngka(parseFloat(vDataviewObj.nilai_payment_klinik) + parseFloat(vDataviewObj.nilai_payment_dokter))}</td>
				<td className="text-end p-1">{UFunc.formatAngka(vDataviewObj.nilai_payment_dokter)}</td>
			</tr>

			<tr className="text-success fw-bolder">
				<td className="text-end p-1" colSpan={2}>TOTAL</td>
				<td className="text-end p-1 border-top">{UFunc.formatAngka(parseFloat(vDataviewObj.nilai_tagihan) - parseFloat(vDataviewObj.nilai_payment_klinik) - parseFloat(vDataviewObj.nilai_payment_dokter))}</td>
				<td className="text-end p-1 border-top">{UFunc.formatAngka(vDataviewObj.nilai_feedokter)}</td>
			</tr>
			</tfoot>
			</table>
			</CCardBody>		
		</CCard>
		</>
		)//>
	}
	const contentCetak = () => {
		if(uDatamainArr.length <= 0) return "";
		//console.log("(Lapfeedokter - contentCetak) vHTMLs => "+vHTMLs)

		/*if(uFilterObj.jenis==="TAHUN") {
			if(parseInt(uFilterObj.dokter_id||"0") > 0)
				return cetakTahundokter();
			return cetakTahunall();
		}//-*/
		if(uFilterObj.jenis==="RANGETANGGAL") {
			if(parseInt(uFilterObj.dokter_id||"0") > 0)
				return cetakBulandokter();
			return cetakBulanall();
		}

		return (<></>)//>
	}
	const cetakBulanall = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		
		const vDokterIdx	= UFunc.getObjectIndeks(uDatadokterArr,uFilterObj.dokter_id);
		const vDoktertext	= "SEMUA DOKTER";

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="4">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? `<div>Cabang: `+vCabangtext+` - `+vDoktertext+`</div>` 
				: `<div>`+vDoktertext+`</div>`)+
			`</td>
			</tr>
			<tr class="idtabelheader">
				<th width="5%">No</th>
				<th align="left">`+(uBahasaObj.word_dokter||"Dokter")+`</th>
				<th width="23%">`+(uBahasaObj.caption_npembayaran||"N.Pembayaran")+` (Rp)</th>
				<th width="20%">`+(uBahasaObj.caption_feenetto||"Total Fee")+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;
		vHTMLs	+= uDatamainArr.map((vItems,vKeys)=>{
			const {
				dokter_caption,nilai_tagihan,nilai_diskon,nilai_payment,nilai_feedokter
			} = vItems;

			return (`
			<tr key={vKeys} valign="top">
				<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
				<td align="left">`+(dokter_caption||"UNDF")+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_tagihan)+`</td>
				<td align="right">`+
				(parseFloat(nilai_feedokter||"0") >= 0 ? UFunc.formatAngka(nilai_feedokter)
					: "("+UFunc.formatAngka(Math.abs(nilai_feedokter))+")"
					)+
				`</td>
			</tr>
			`);
		}).join(``);

		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="2" align="center">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_tagihan)+`</td>
			<td align="right">`+
				(parseFloat(uDatasumArr.nilai_feedokter) >= 0 
					? UFunc.formatAngka(uDatasumArr.nilai_feedokter) 
					: "("+UFunc.formatAngka(Math.abs(uDatasumArr.nilai_feedokter))+")")+
			`</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	const cetakBulandokter = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		
		const vDokterIdx	= UFunc.getObjectIndeks(uDatadokterArr,uFilterObj.dokter_id);
		const vDoktertext	= uDatadokterArr[vDokterIdx].nama_dokter;

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="6">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? `<div>Cabang: `+vCabangtext+` - `+vDoktertext+`</div>` 
				: `<div>`+vDoktertext+`</div>`)+
			`</td>
			</tr>
			<tr class="idtabelheader" valign="top">
				<th width="3%">No</th>
				<th width="10%">`+(uBahasaObj.caption_tgllunas||"Tgl Lunas")+`</th>
				<th width="10%">`+(uBahasaObj.caption_tglperiksa||"Tgl Periksa")+`</th>
				<th align="left">`+(uBahasaObj.word_pasien||"Pasien")+`</th>
				<th width="12%">`+(uBahasaObj.caption_ntagihan||"N.Pembayaran")+` (Rp)</th>
				<th width="14%">`+(uBahasaObj.caption_feenetto||"Total Fee")+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;
		vHTMLs 	+= uDatamainArr.map((vItems,vKeys)=>{
			const {
				tgl_lunas,tgl_periksa,
				pasien_caption,nilai_tagihan,nilai_diskon,nilai_payment,nilai_feedokter
			} = vItems;

			return (`
			<tr key={vKeys} valign="top">
				<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
				<td align="left"><small>`+UFunc.TglAngka(tgl_lunas)+`</small></td>
				<td align="left"><small>`+UFunc.TglAngka(tgl_periksa)+`</small></td>
				<td align="left">`+(pasien_caption||"UNDF")+`</td>
				<td align="right"><small>`+UFunc.formatAngka(nilai_tagihan)+`</small></td>
				<td align="right">`+
				(parseFloat(nilai_feedokter||"0") >= 0 ? UFunc.formatAngka(nilai_feedokter)
					: "("+UFunc.formatAngka(Math.abs(nilai_feedokter))+")"
					)+
				`</td>
			</tr>
			`);
		}).join(``);
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="4" align="center">JUMLAH</td>
			<td align="right"><small>`+UFunc.formatAngka(uDatasumArr.nilai_tagihan)+`</small></td>
			<td align="right">`+
				(parseFloat(uDatasumArr.nilai_feedokter) >= 0 
					? UFunc.formatAngka(uDatasumArr.nilai_feedokter) 
					: "("+UFunc.formatAngka(Math.abs(uDatasumArr.nilai_feedokter))+")")+
			`</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	const cetakTahunall = () => {//--TIDAK_DIPAKAI--/
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		const vKolwidth		= Math.round(75/13);

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="15">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? `<div>Cabang: `+vCabangtext+` - SEMUA DOKTER</div>` 
				: `<div>SEMUA DOKTER</div>`)+
			`</td>
			</tr>
			<tr class="idtabelheader">
				<th width="5%">No</th>
				<th align="left">`+(uBahasaObj.word_dokter||"Dokter")+`</th>`+
				(cBulanPendek.map(vItems=>{
					return (`<th width="`+vKolwidth+`%">`+vItems+`</th>`)
				}).join(""))+
				`<th width="`+vKolwidth+`%">`+(uBahasaObj.word_total||"Total")+`</th>
			</tr>
			</thead>
			<tbody>`;
		uDatamainArr.forEach((vItems,vKeys)=>{
			const {
				dokter_caption,id
			} = vItems;

			vHTMLs 	+= `
			<tr key={vKeys} valign="top">
				<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
				<td align="left">`+(dokter_caption||"UNDF")+`</td>`+
				(cBulanPendek.map((vItembulan,vKeybulan)=>{
					return (`<td align="right">`+UFunc.formatAngka(vItems["nilai_"+UFunc.leadZero(vKeybulan)])+`</td>`)
				}).join(""))+
				`<td align="right">`+
				(parseFloat(vItems["nilai_total"]||"0") >= 0 
					? UFunc.formatAngka(vItems["nilai_total"])
					: "("+UFunc.formatAngka(Math.abs(vItems["nilai_total"]))+")"
				)+
				`</td>
			</tr>
			`;
		});

		const vNolArr		= [0,0,0,0,0,0,0,0,0,0,0,0,0];
		const vTotalArr		= uDatasumArr.nilai_arr || vNolArr;
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="2" align="center">JUMLAH</td>`+
			(vTotalArr.map(vItems=>{
				return (
				`<td align="right">`+
				(parseFloat(vItems||"0") >= 0 
					? UFunc.formatAngka(vItems) 
					: "("+UFunc.formatAngka(Math.abs(vItems))+")")+
				`</td>`
				)
			})).join("")+
			`</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	const cetakTahundokter = () => {//--TIDAK_DIPAKAI--/
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";
		
		const vDokterIdx	= UFunc.getObjectIndeks(uDatadokterArr,uFilterObj.dokter_id);
		const vDoktertext	= uDatadokterArr[vDokterIdx].nama_dokter;

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="6">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? `<div>Cabang: `+vCabangtext+` - `+vDoktertext+`</div>` 
				: `<div>`+vDoktertext+`</div>`)+
			`</td>
			</tr>
			<tr class="idtabelheader" valign="top">
				<th width="5%">No</th>
				<th align="left">`+(uBahasaObj.word_bulan||"Bulan")+`</th>
				<th width="15%">`+(uBahasaObj.caption_ntagihan||"N.Tagihan")+` (Rp)</th>
				<th width="13%">`+(uBahasaObj.caption_potdiskon||"Pot.Diskon")+` (Rp)</th>
				<th width="13%">`+(uBahasaObj.caption_potpayment||"Pot.Payment")+` (Rp)</th>
				<th width="20%">`+(uBahasaObj.caption_feenetto||"Total Fee")+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;
		uDatamainArr.forEach((vItems,vKeys)=>{
			const {
				bulan_caption,nilai_tagihan,nilai_diskon,nilai_payment,nilai_feedokter
			} = vItems;

			vHTMLs 	+= `
			<tr key={vKeys} valign="top">
				<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
				<td align="left">`+(bulan_caption||"UNDF")+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_tagihan)+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_diskon)+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_payment)+`</td>
				<td align="right">`+
				(parseFloat(nilai_feedokter||"0") >= 0 ? UFunc.formatAngka(nilai_feedokter)
					: "("+UFunc.formatAngka(Math.abs(nilai_feedokter))+")"
					)+
				`</td>
			</tr>
			`;
		});
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="2" align="center">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_tagihan)+`</td>
			<td colspan="2" align="center">&nbsp;</td>
			<td align="right">`+
				(parseFloat(uDatasumArr.nilai_feedokter) >= 0 
					? UFunc.formatAngka(uDatasumArr.nilai_feedokter) 
					: "("+UFunc.formatAngka(Math.abs(uDatasumArr.nilai_feedokter))+")")+
			`</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Lapfeedokter - apiLoadawal) LEWaT "); 
		let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); },2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapfeedokter/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatadokterArr(JSON.parse(output_string.datadokter||"[]"));
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapfeedokter - apiLoadawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapfeedokter - apiLoadawal) catch-error : "+error);
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddata = () => {
		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		let vApiurl	=  "";
		if(vTmpFilterObj.jenis==="TAHUN") {
			vApiurl = "ld_tahunall";
			if(parseInt(vTmpFilterObj.dokter_id) > 0)
				vApiurl = "ld_tahundokter"
		} else {
			vApiurl = "ld_bulanall";
			if(parseInt(vTmpFilterObj.dokter_id) > 0)
				vApiurl = "ld_bulandokter"
		}
		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapfeedokter/"+vApiurl;

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				setDlgformshow(false);
				setDataloaded(true);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapfeedokter - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapfeedokter - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL);

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		(uDatamainArr[vArridx]||{}).is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangid : uFilterObj.cabang_id,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapfeedokter/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			(uDatamainArr[vArridx]||{}).is_loading = false;
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataitem||"[]");
				(uDatamainArr[vArridx]||{}).items_arr = vLoadArr;

				uDlgviewObj.data_obj = {...(uDatamainArr[vArridx]||{}),items_arr : vLoadArr}
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapfeedokter - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapfeedokter - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadawal();

		return () => {
			setDataloaded(false);
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false);setDlgviewshow(false);
			setDatamainArr([]); setDataloaded(false); 
			setDatadokterArr([])
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uDatamainArr.length <= 0)
			{ setDatadefaultArr([]); return }
		setDatadefaultArr([{init_change:"1"},...uDatamainArr]) 
	},[uDatamainArr])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length)
		if(uDatamainArr.length <= 0)
			{ setDatasumObj({}); return }

		let vBruto		= 0;
		let vNetto		= 0;
		let vNilaiArr	= [0,0,0,0,0,0,0,0,0,0,0,0,0];
		if(uFilterObj.jenis === "RANGETANGGAL") {
			vBruto	= uDatamainArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_tagihan||"0"), 0);
			vNetto	= uDatamainArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_feedokter||"0"), 0);
		}
		if(uFilterObj.jenis === "TAHUN") {
			vBruto	= uDatamainArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_tagihan||"0"), 0);
			vNetto	= uDatamainArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_feedokter||"0"), 0);

			if(parseFloat(uFilterObj.dokter_id||"0") <= 0) {
				let vNilaitmpArr	= cBulanPendek.map((vItembulan,vKeybulan)=>{
					return (
						uDatamainArr.reduce(
						(vStores,vItems) => vStores = vStores + parseFloat(vItems["nilai_"+UFunc.leadZero(vKeybulan)]||"0"), 0)
					)
				});
				vNilaitmpArr.push(
					uDatamainArr.reduce(
						(vStores,vItems) => vStores = vStores + parseFloat(vItems["nilai_total"]||"0"), 0)
				)

				vNilaiArr = vNilaitmpArr;
			}
		}
		setDatasumObj({
			nilai_tagihan: vBruto,
			nilai_feedokter: vNetto,
			nilai_arr: vNilaiArr,
		});
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		/*//--LOAD_PER_PAGE--/
		if(uFirstload==="YA") return;

		setIDselect(0);
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
		//--END LOAD_PER_PAGE--*/

		//--LOAD_ALL_VIEW_PER_PAGE--/
		setIDselect(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		setHeaderDlgform("Pilih Filter Laporan");
		setFormdataObj({...uFilterObj, init_change: 1});
		setDlgformshow(true)
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) { apiLoadawal(); }
		else { setDataloaded(false); }
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		if(uFormdataObj.jenis === "RANGETANGGAL") {
			vTitelmain += "<b>"+UFunc.TglAngka(uFormdataObj.tgl_awal)+"</b>"+
				" s.d. "+
				"<b>"+UFunc.TglAngka(uFormdataObj.tgl_akhir)+"</b>";
		} else {
			vTitelmain += "Tahun <b>"+uFormdataObj.thn+"</b>";
		}

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr.length > 1 
			? (uDatacabangArr[vCabangIdx]||{}).cabang_nama + " &middot; " : "";
		if(parseInt(uFormdataObj.dokter_id||"0") <= 0) 
			vTitelsub	+= "[SEMUA DOKTER]";
		else {
			const vDokterIdx= UFunc.getObjectIndeks(uDatadokterArr,uFormdataObj.dokter_id);
			vTitelsub	+= "<b>"+(uDatadokterArr[vDokterIdx]||{}).nama_dokter+"</b>";
		}

		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Lapfeedokter - [uIsDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uIsDlgformshow) 
			{ setFormdataObj({}); return }

		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");
		if(uFormdataObj.jenis === undefined)
			uFormdataObj.jenis = "RANGETANGGAL";
		if(uFormdataObj.dokter_id === undefined)
			uFormdataObj.dokter_id = uTokenObj.user_dokterid || "0";

		const vTanggalnowDT 		= new Date();
		const vTanggalLastmonthDT 	= new Date();//UFunc.toDate("2023-02-03");
		if(uFormdataObj.tgl_awal === undefined) {
			vTanggalLastmonthDT.setMonth(vTanggalLastmonthDT.getMonth() - 1);
			uFormdataObj.tgl_awal = vTanggalLastmonthDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalLastmonthDT.getMonth()+1)+"-26"+
			"";
		}
		if(uFormdataObj.tgl_akhir === undefined)
			uFormdataObj.tgl_akhir = vTanggalnowDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+"-25"+
			"";
		if(uFormdataObj.thn === undefined)
			uFormdataObj.thn = vTanggalnowDT.getFullYear();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			const vElfocus = document.getElementById("inpcabangid");
			vElfocus && vElfocus.focus();
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ setDlgviewObj({}); return }
	},[uISDlgviewshow])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); setIDselect(0); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		let vNamasort = (uSortedObj.nama||"").toLowerCase();
		//if(uFilterObj.jenis==="TAHUN" && parseInt(uFilterObj.dokter_id||"0") <= 0) vNamasort = parseInt(uSortedObj.nama||"0");
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]);
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])
	
	//console.log("(Lapfeedokter) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg) return (
		<CCard className="classbgcard">
		<CCardHeader className="fw-bolder">Page 500</CCardHeader>
		<CCardBody className="mx-md-5 p-md-5">
		<div className="fst-italic">
		{UFunc.renderHTML(uHtml500msg)}
		</div>
		</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
			<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
			<CCardBody className="mx-lg-5 p-lg-5">
			<div className="mx-md-5 p-md-5 fst-italic classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between px-1 px-md-3">
		<div>
			<div className="d-none d-md-block">{UFunc.renderHTML(uCaptionObj.title)}</div>
			<div className="d-md-none classfontsmaller"><small>{UFunc.renderHTML(uCaptionObj.title)}</small></div>
			<span className="classfontsmaller text-primary">
				<small>
				{UFunc.renderHTML(uCaptionObj.title_sub)}
				</small>
			</span>
		</div>

		<div>
			<span className="d-none d-md-inline">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikon classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>

				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikon classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
				<span className="me-1">&middot;</span>
			</span>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
			</span>
			<span className="ms-1 d-md-none">&middot;</span>

			<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikExcel()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentLaporan()}</CCardBody>

		{(!isMobile && uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
		)}
		</CCard>
		</>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		<MyDialogview
			options={{centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.dlg_headers||""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)//>
}	

export default Lapfeedokter