import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormSelect,CInputGroup,CInputGroupText,CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogview,
	MyDialogpasien,
	MyCardformpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Kasirasuransi	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelPreview 	= uHeaderActionObj.isHeaderPreview || false;

	//--VIEWS_VARS--/
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformdataObj,setDlgformdataObj]	= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//--END DLGVIEW--/

	//--DLGVIEW--/
	//--END DLGVIEW--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikBayar = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDlgformdataObj.header 	= "Input Bayar: "+(uDatamainArr[vArridx].nama_asuransi||"Undf").toUpperCase();
		uDlgformdataObj.tabelid = _IDTABEL;
		uDlgformdataObj.nama_asuransi	= uDatamainArr[vArridx].nama_asuransi || "nama_asuransi";
		uDlgformdataObj.no_nota			= uDatamainArr[vArridx].no_nota || "no_nota";
		uDlgformdataObj.nilai_sisa		= parseFloat(uDatamainArr[vArridx].nilai_asuransi||"0") - 
			parseFloat(uDatamainArr[vArridx].nilai_bayar||"0");

		uDlgformdataObj.tgl_bayar 		= UFunc.DbDate();
		uDlgformdataObj.nilai_bayar		= 0;
		setDlgformshow(true);
	}
	const hdlKlikDetilitem = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDlgviewdataObj.header 	= "Detil Nota: "+(uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase();
		uDlgviewdataObj.tabelid = _IDTABEL;
		uDlgviewdataObj.jenis_view	= "DETIL";

		if((uDatamainArr[vArridx].item_arr||[]).length > 0) {
			uDlgviewdataObj.item_arr	= uDatamainArr[vArridx].item_arr||[];

			setDlgviewshow(true); return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikHistoribayar = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDlgviewdataObj.header 	= "Histori Klaim: "+(uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase();
		uDlgviewdataObj.tabelid = _IDTABEL;
		uDlgviewdataObj.jenis_view	= "HISTORI";
		uDlgviewdataObj.nilai_sisa		= parseFloat(uDatamainArr[vArridx].nilai_asuransi||"0") - 
			parseFloat(uDatamainArr[vArridx].nilai_bayar||"0");

		if((uDatamainArr[vArridx].bayar_arr||[]).length > 0) {
			uDlgviewdataObj.bayar_arr	= uDatamainArr[vArridx].bayar_arr||[];

			setDlgviewshow(true); return;
		}

		apiLoadhistori(_IDTABEL);
	}
	const hdlKlikDlgsimpan = () => {
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uDlgformdataObj.tabelid);

		const vTglperiksa 	= uDatamainArr[vArridx].tgl_periksa || UFunc.DbDate();
		const vSisapiutang 	= parseFloat(uDlgformdataObj.nilai_sisa||"0");

		let vElfocus 	= document.getElementById("inptglbayar");
		if((uDlgformdataObj.tgl_bayar||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Bayar Tidak Valid !"); return;
		}
		const vTglbayarDT 	= UFunc.toDate(uDlgformdataObj.tgl_bayar);
		if(UFunc.DateIsBeyond(vTglbayarDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tgl Bayar Telah MELEWATI Tanggal HARI INI !"); return;	
		}
		if(UFunc.SelisihHari(vTglperiksa, uDlgformdataObj.tgl_bayar) < 0) {
			vElfocus && vElfocus.focus();
			showToast("Tgl Bayar KURANG dari Tanggal Periksa.."+
				"<br/>Tgl Periksa: <b>"+UFunc.HariAngka(vTglperiksa)+"</b>"+
				"<br/><br/>Mohon Diperbaiki !"+
				""); return;	
		}

		vElfocus 	= document.getElementById("inpnilaibayar");
		//showToast("uDlgformdataObj.nilai_bayar => "+uDlgformdataObj.nilai_bayar);
		//showToast("uDlgformdataObj.nilai_bayar => "+parseFloat(uDlgformdataObj.nilai_bayar));
		if((parseFloat(uDlgformdataObj.nilai_bayar)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Bayar Tidak Valid.."+
				"<br/>Nilai Bayar yang Dituliskan: <b>"+(uDlgformdataObj.nilai_bayar)+"</b>"+
				"<br/><br/>Mohon Diperbaiki !"+
				""); return;	
		}
		if(parseFloat(uDlgformdataObj.nilai_bayar||"0") > vSisapiutang) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Bayar Melebihi KLAIM yang TERSISA.."+
				"<br/>Sisa Klaim: <b>Rp "+UFunc.formatAngka(vSisapiutang)+"</b>"+
				"<br/>Nilai Bayar: <b>Rp "+UFunc.formatAngka(uDlgformdataObj.nilai_bayar)+"</b>"+
				"<br/><br/>Mohon Diperbaiki !"+
				""); return;	
		}//-*/
		vElfocus 	= document.getElementById("inpketbayar");
		if((uDlgformdataObj.keterangan||"").trim() !== "")
			if((uDlgformdataObj.keterangan||"").length < 3) {
				vElfocus && vElfocus.focus();
				showToast("Keterangan Tidak Valid.."+
					"<br/><b>Minimal 3 Karakter</b>"+
					"<br/><br/>Mohon Diperbaiki !"+
					""); return;	
			}

		apiProsesbayar();
	}
	//--END HANDEL--/

	//--INIT_FUNC--/
	const initSelectrow = (_IDTABEL) => {
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vRowidx);
	}
	//--END INIT_FUNC--/

	//--CONTENT_FUNC--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_kasirasuransi || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg) === parseInt(uPageActive));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.caption_namaasuransi||"Nama Asuransi"}</CCol>
			<CCol md="3" className="d-flex justify-content-between">
				<div className="w-50 text-center">{uBahasaObj.caption_klaimasuransi||"Klaim Asuransi"}<small className="d-block">(Rp)</small></div>
				<div className="w-50 text-center">{uBahasaObj.word_terbayar||"Terbayar"}<small className="d-block">(Rp)</small></div>
			</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_sisaklaim||"Sisa Klaim"}<small className="d-block">(Rp)</small></CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-md-none">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,nama_asuransi,id_asuransi,tgl_periksa,nama_pasien,no_nota,
				nilai_netto,nilai_asuransi,nilai_bayar,
				is_processing,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama_asuransi||"Undf").toUpperCase();

			const vPasiencaption	= vKeyword==="" ? (nama_pasien||"Undf") 
				: (nama_pasien||"Undf").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vAsuransicaption	= vKeyword==="" ? (nama_asuransi||"Undf") 
				: (nama_asuransi||"Undf").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vNonota	= vKeyword==="" ? (no_nota||"") 
				: (no_nota||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vTglperiksa	= vKeyword==="" ? UFunc.TglAngka(tgl_periksa) 
				: UFunc.TglAngka(tgl_periksa).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			
			return (
			<tr id={"idtr"+id} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}
				 key={vKeys}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-md-start d-none d-md-block">{UFunc.renderHTML(vTglperiksa)}</CCol>
				<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
					<div>
						<div className="text-capitalize text-info">{UFunc.renderHTML(vAsuransicaption)}</div>
						<small className="text-capitalize d-none d-md-block">{UFunc.renderHTML(vPasiencaption)}</small>
					</div>

					{(is_processing||false)===true ? (
						<CSpinner color="primary" className="d-md-none" size="sm"/>
					) : (
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm"
							className="p-0" color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikDetilitem(id)}>
							<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
							Detil Item Terapi
						</CDropdownItem>

						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHistoribayar(id)}>
							<CIcon icon="cilMoney" className="classikonbox mx-0 me-2" height={25}/>
							Histori Pembayaran
						</CDropdownItem>

						<CDropdownDivider/>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikBayar(id)}>
							<CIcon icon="cilDollar" className="classikonbox mx-0 me-2" height={25}/>
							Input Pembayaran
						</CDropdownItem>
						</CDropdownMenu>
						</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">{UFunc.TglAngka(tgl_periksa)}</CCol>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_klaimasuransi||"Nilai Klaim"} (Rp)</CCol>
				<CCol xs="6" className="px-1 d-md-none fw-bolder">{UFunc.formatAngka(nilai_asuransi)}</CCol>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"Terbayar"} (Rp)</CCol>
				<CCol xs="6" className="px-1 d-md-none text-success">{UFunc.formatAngka(nilai_bayar)}</CCol>
				
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				
				<CCol md="3" className="px-0 d-none d-md-flex justify-content-between">
					<div className="px-1 w-50 text-end fw-bolder">{UFunc.formatAngka(nilai_asuransi)}</div>
					<div className="px-1 w-50 text-end text-success">{UFunc.formatAngka(nilai_bayar)}</div>
				</CCol>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_sisapiutang||"Sisa Piutang"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 fw-bolder text-danger">
					{UFunc.formatAngka(parseFloat(nilai_asuransi) - parseFloat(nilai_bayar))}
				</CCol>

				<CCol md="2" className="px-0 text-md-center d-none d-md-block">
					{(is_processing||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CDropdown>
					<CDropdownToggle caret={false} size="sm"
						className="p-0" 
						color="transparent">
						<CIcon icon="cilChevronBottom" className="classikonbox" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikDetilitem(id)}>
						<CIcon icon="cilFindInPage" className="classikonbox me-2" height={25}/>
						Detil Nota
						</CDropdownItem>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHistoribayar(id)}>
						<CIcon icon="cilMoney" className="classikonbox me-2" height={25}/>
						Histori Pembayaran
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>

					<CButton color="dark" size="sm" className="py-1"
						onClick={()=>hdlKlikBayar(id)}>
						Klaim<CIcon icon="cilChevronDoubleRight" className="ms-1 align-middle"/>
					</CButton>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uDlgformdataObj.tabelid);
		if(vArridx < 0) return (<></>)//>

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="4" className="">
			<small className="d-md-none">Tgl Bayar</small>
			<span className="d-none d-md-block">Tgl Bayar</span>
		</CCol>
		<CCol className="text-end text-md-start">
			{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "PENGELOLA") ? (
			<CFormInput size="sm" type="date"
				value={uDlgformdataObj.tgl_bayar}
				onChange={(e)=>{uDlgformdataObj.tgl_bayar=e.target.value;setEfekview(!uEfekview)}}
				id="inptglbayar"/>
			) : (
			<b>{UFunc.TglAngka()}</b>
			)}
		</CCol>
		</CRow>

		<CRow className="my-3">
		<CCol xs="4" className="">
			<small className="d-md-none">Asuransi</small>
			<span className="d-none d-md-block">Nama Asuransi</span>
		</CCol>
		<CCol className="text-end text-md-start fw-bolder">
			{uDlgformdataObj.nama_asuransi}
		</CCol>
		</CRow>

		<CRow className="my-3">
		<CCol xs="4" className="">
			<small className="d-md-none">No.Nota</small>
			<span className="d-none d-md-block">No.Nota</span>
		</CCol>
		<CCol className="text-end text-md-start fw-bolder">
			{uDlgformdataObj.no_nota}
		</CCol>
		</CRow>

		<CRow className="my-3">
		<CCol xs="4" className="">
			<small className="d-md-none">Sisa Klaim</small>
			<span className="d-none d-md-block">Sisa Klaim</span>
		</CCol>
		<CCol className="text-end text-md-start fw-bolder text-danger">
			Rp {UFunc.formatAngka(parseFloat(uDatamainArr[vArridx].nilai_asuransi||"0") - 
			parseFloat(uDatamainArr[vArridx].nilai_bayar||"0"))}
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4" className="">
			<small className="d-md-none">Nilai Bayar</small>
			<span className="d-none d-md-block">Nilai Bayar</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<NumberFormat 
				value={uDlgformdataObj.nilai_bayar||"0"}
				displayType={'text'} 
				allowNegative={false}
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => uDlgformdataObj.nilai_bayar=values.value}
				renderText={values => (
					<CFormInput size="sm"
						className="text-end"
						value={values}
						onChange={(e)=>{uDlgformdataObj.nilai_bayar=e.target.value;setEfekview(!uEfekview)}}
						onFocus={(e)=>e.target.select()}
					id="inpnilaibayar"/>
				)} 
			prefix={isMobile?"":"Rp"}/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="">
			<small className="d-md-none">Keterangan</small>
			<span className="d-none d-md-block">Keterangan</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CFormInput size="sm" maxLength={255}
				value={uDlgformdataObj.keterangan}
				onChange={(e)=>{uDlgformdataObj.keterangan=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnilaibayar"/>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>
		if(uDlgviewdataObj.jenis_view === "DETIL")  return contentViewdetil();
		if(uDlgviewdataObj.jenis_view === "HISTORI") return contentViewhistori();

		return (<></>)//>
	}
	const contentViewdetil = () => {
		if(uDlgviewdataObj.jenis_view !== "DETIL") return(<></>)//>

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uDlgviewdataObj.tabelid);
		if(vArridx < 0) return (<></>)//>

		return (
		<small>
		<CRow>
		<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="classfontsmaller py-0 pt-1">Detil Nota</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Tgl Periksa</div>
					<div className="text-end fw-bolder">{UFunc.TglAngka(uDatamainArr[vArridx].tgl_periksa)}</div>
				</div>
				<div className="d-flex justify-content-between">
					<div>Nama Pasien</div>
					<div className="text-end fw-bolder">{(uDatamainArr[vArridx].nama_pasien)}</div>
				</div>
				<div className="d-flex justify-content-between">
					<div>Dokter</div>
					<div className="text-end fw-bolder text-primary">{(uDatamainArr[vArridx].dokter_caption)}</div>
				</div>
				<div className="d-flex justify-content-between">
					<div>Asuransi</div>
					<div className="text-end fw-bolder" style={{color:"purple"}}>{(uDatamainArr[vArridx].nama_asuransi)}</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		<CCol>
			<CCard className="mb-3">
			<CCardHeader className="classfontsmaller py-0 pt-1">Klaim Summary</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>N.Transaksi (Rp)</div>
					<div className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_netto)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
					<div>Tanggungan Pasien (Rp)</div>
					<div className="text-end">
						{UFunc.formatAngka(parseFloat(uDatamainArr[vArridx].nilai_netto) - parseFloat(uDatamainArr[vArridx].nilai_asuransi))}
					</div>
				</div>

				<div className="my-2 classborderbottom"/>

				<div className="d-flex justify-content-between">
					<div>Klaim Asuransi (Rp)</div>
					<div className="text-end fw-bolder">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_asuransi)}</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow>
		<CCol>
			<CCard className="mb-3">
			<CCardHeader className="classfontsmaller py-0 pt-1">Detil Terapi</CCardHeader>
			<CCardBody className="px-0">
				<table className="table table-borderless table-striped" id="idtabeldetil">
				<thead>
				<tr className="text-center align-top fw-bolder classfontsmaller">
					<td width={30} className="text-end">No</td>
					<td className="text-start">Nama Terapi</td>
					<td width="30%" className="">Nilai (Rp)</td>
				</tr>
				</thead>

				<tbody className="classfontsmaller">
				{(uDatamainArr[vArridx].item_arr||[]).map((vItems,vKeys)=>{
					return (
					<tr className="align-top" key={vKeys}>
						<td className="px-1 text-end">{vKeys+1}.</td>
						
						<td className="px-1 text-start">
						<div>{vItems.terapi_caption}</div>
						{parseInt(vItems.qty||"1") > 1 && (
						<ul>
						<li>(<b>{UFunc.formatAngka(vItems.qty)}</b>UNT x Rp<b>{UFunc.formatAngka(vItems.nilai_satuan)}</b>)</li>
						</ul>
						)}
						</td>
						
						<td className="px-1 text-end fw-bolder">
							{UFunc.formatAngka(vItems.nilai_terapi)}
						</td>
					</tr>
					)
				})}
				</tbody>

				<tfoot className="fw-bolder border-top border-top-light classfontsmaller">
				<tr>
				<td colSpan={2} className="text-end">JUMLAH</td>
				<td className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_bruto)}</td>
				</tr>

				{parseFloat(uDatamainArr[vArridx].nilai_diskon||"0") > 0 && (
				<tr>
				<td colSpan={2} className="text-end">DISKON</td>
				<td className="text-end text-danger border-top border-top-light">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_diskon)}</td>
				</tr>
				)}
				</tfoot>
				</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentViewhistori = () => {
		if(uDlgviewdataObj.jenis_view !== "HISTORI") return(<></>)//>

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uDlgviewdataObj.tabelid);
		if(vArridx < 0) return (<></>)//>
		
		const vTotalbayar	= (uDatamainArr[vArridx].bayar_arr||[]).
			reduce((vSum,vItems) =>
				vSum + parseFloat(vItems.nilai_bayar||"0"),
			0);

		return (
		<small>
		<CRow>
		<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Transaksi</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Tgl Periksa</div>
					<div className="text-end fw-bolder">{UFunc.TglAngka(uDatamainArr[vArridx].tgl_periksa)}</div>
				</div>
				<div className="d-flex justify-content-between">
					<div>Nama Pasien</div>
					<div className="text-end fw-bolder">{(uDatamainArr[vArridx].nama_pasien)}</div>
				</div>

				<div className="my-2 classborderbottom"/>

				<div className="d-flex justify-content-between">
					<div>Asuransi</div>
					<div className="text-end fw-bolder" style={{color:"purple"}}>{(uDatamainArr[vArridx].nama_asuransi)}</div>
				</div>
				<div className="d-flex justify-content-between">
					<div>Nilai Klaim (Rp)</div>
					<div className="text-end fw-bolder" style={{color:"purple"}}>{UFunc.formatAngka(uDatamainArr[vArridx].nilai_asuransi)}</div>
				</div>
				<div className="d-flex justify-content-between">
					<div>Sisa Klaim (Rp)</div>
					<div className="text-end fw-bolder text-danger">
					{UFunc.formatAngka(uDlgviewdataObj.nilai_sisa)}
					</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Histori Pembayaran</CCardHeader>
			<CCardBody className="px-0">
				<table className="table table-borderless table-striped" id="idtabeldetil">
				<thead>
				<tr className="text-center align-top fw-bolder classfontsmaller">
					<td width={30} className="text-end">No</td>
					<td className="text-start">Keterangan</td>
					<td width="25%" className="">N.Bayar (Rp)</td>
				</tr>
				</thead>

				<tbody>
				{(uDatamainArr[vArridx].bayar_arr || []).map((vItems,vKeys)=>{
					return (
					<tr className="align-top" key={vKeys}>
						<td className="px-1 text-end">{vKeys+1}.</td>
						<td className="px-1 text-start">
							<div className="fw-bolder">{UFunc.TglAngka(vItems.tgl_bayar)}</div>
							{(vItems.keterangan||"")!=="" && (
							<small className="fst-italic d-block">
								{vItems.keterangan}
							</small>
							)}
						</td>
						<td className="px-1 text-end fw-bolder">{UFunc.formatAngka(vItems.nilai_bayar)}</td>
					</tr>
					)
				})}
				</tbody>

				<tfoot className="fw-bolder border-top border-top-light classfontsmaller">
				<tr>
				<td colSpan={2} className="text-end">JUMLAH BAYAR</td>
				<td className="text-end text-success">{UFunc.formatAngka(vTotalbayar)}</td>
				</tr>
				</tfoot>
				</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||0;
		setHtml500msg();

		const vKeyword 		= uKeywordObj.kwd_kasirasuransi || "";
		const vHandelview	= (_PAGE <= 0 || uFirstload==="YA")?"YA":"TIDAK";
		let vDatamainArr	= uDatamainArr;
		if(vKeyword.length < 3) {
			vDatamainArr 	= []
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) === 1);
			} else
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) !== uPageActive);
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			send_maxdata: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirasuransi/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				//console.log("(Pasien - apiLoaddata) vTmpArr : "+JSON.stringify(vTmpArr));
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.total_data||uJmlData))
				else setJmlData(vTmpArr.length);

				setDatamainArr([...vDatamainArr,...vTmpArr]);
		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				setFirstload("TIDAK");
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			uDatamainArr[vArridx].is_processing = false;
			setDlgviewshow(true);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirasuransi/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			if(output_string.tampil) {
				const vTmpArr	= JSON.parse(output_string.detil_list || "[]");
				uDlgviewdataObj.item_arr 		= vTmpArr;
				uDatamainArr[vArridx].item_arr 	= vTmpArr;

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			console.log("(Pasien - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadhistori = (_IDTABEL) => {
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			uDatamainArr[vArridx].is_processing = false;
			setDlgviewshow(true);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirasuransi/ld_bayar";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			if(output_string.tampil) {
				const vTmpArr	= JSON.parse(output_string.bayar_list || "[]");
				uDlgviewdataObj.bayar_arr 		= vTmpArr;
				uDatamainArr[vArridx].bayar_arr = vTmpArr;

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			console.log("(Pasien - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbayar = () => {
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,uDlgformdataObj.tabelid);
		const vElbutton = document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlgformdataObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirasuransi/pr_bayar";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				const vTmpObj	= {
					tgl_bayar : uDlgformdataObj.tgl_bayar,
					nilai_bayar : parseFloat(uDlgformdataObj.nilai_bayar||"0"),
					keterangan : (uDlgformdataObj.keterangan||"").trim(),
					id : 0,
				}

				uDatamainArr[vArridx].nilai_bayar = parseFloat(uDatamainArr[vArridx].nilai_bayar || "0") +
					parseFloat(uDlgformdataObj.nilai_bayar||"0");

				if((uDatamainArr[vArridx].bayar_arr||[]).length > 0)
					uDatamainArr[vArridx].bayar_arr = [...(uDatamainArr[vArridx].bayar_arr||[]),vTmpObj]; 

				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiProsesbayar) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Pasien - apiProsesbayar) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/


	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//const vPageinit	= uPageActive;
		//console.log("(Pasien - []) uLastinitObj.initPage => "+(uLastinitObj.initPage))

		//--PAGE_LOAD_ALL--/
		apiLoaddata();
		//--END PAGE_LOAD_ALL--*/

		/*//--PAGE_LOAD_PER_PAGE--/
		if(JSON.stringify(uLastinitObj||{}) !== "{}") {
			setPageActive(uLastinitObj.initPage||1);
			apiLoaddata(uLastinitObj.initPage||1);
		}
		//--END PAGE_LOAD_PER_PAGE--*/

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
    		setInitselectedObj({});
			setDlgformshow(false); setDlgviewshow(false);
			setFirstload("YA");
			setPageActive(1);
    	}
	},[])
	React.useEffect(()=>{ 
    	if(JSON.stringify(uLastinitObj||{}) !== "{}") return;
    	if(uPageActive <= 0) { setPageActive(1); return }

		setRowselect(-1); 
		const vDataviewArr	= uDatamainArr.filter(vItems=>parseInt(vItems.page||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{ 
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		const vKeyword 		= uKeywordObj.kwd_kasirasuransi || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData,uJmlData])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelPreview) return;

		uHeaderActionObj.isHeaderPreview = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uNavigate("/subbiling/kasirasuransi/previewbayar");
	},[uHandelPreview])
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformdataObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			let vElfocus = document.getElementById("inpnilaibayar");
			if(!isMobile) vElfocus && vElfocus.focus();
		},350)
	},[uISDlgformshow])

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
		<small className="align-middle text-primary">{uBahasaObj.caption_dataasuransi||"Data Piutang Asuransi"}</small>
		<span className="mx-1 d-md-none">&middot;</span>
		<small className="d-md-none text-primary align-middle">{(()=>{
			const vKeyword = (uKeywordObj.kwd_kasirasuransi||"").trim();
			if(vKeyword.length < 3) return (<>&#8734;</>);

			return (<>[{UFunc.formatAngka(uJmlData)}]</>)
		})()}</small>
		</div>
		<span className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		<small className="d-none">({uJmlData} - {uMaxData})</small>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{centered:false}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.header || ""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.header || ""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
		</>
	)
}	

export default Kasirasuransi