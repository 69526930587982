import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyHeaderProfile,
	MyPagination,
	//MyButton,
	//MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 		= require('../../package.json')
const Updatedlog 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	/*const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0);

	const [uDatamainObj,setDatamainObj]		= React.useState({})//-*/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	const uDatalogsArr	= [
		{version:"4.5.0",updated_arr:[
			{teks_menu:"Klinik Sistem >> Pasien >> Data Pasien >> Input / Edit Pasien",teks_content:"Update Fitur, Tambahan Administrasi Wilayah Pasien, untuk Keperluan Registrasi IHS Number Satusehat."},
			{teks_menu:"Klinik Sistem >> Kunjungan Pasien >> Status Kunjungan",teks_content:"Update Fitur, Tambahan Administrasi Wilayah Pasien, untuk Keperluan Registrasi IHS Number Satusehat."},
			{teks_menu:"Klinik Sistem >> Kasir >> Pembayaran Kasir >> Input Pembayaran",teks_content:"Update Fitur, Bisa Menambahkan Item UNLIMITED.<br/>Merubah QTY dan DISKON.<br/>Tambah Catatan KASIR."},
			{teks_menu:"Klinik Sistem >> Kasir >> Rekap Nota",teks_content:"Update Fitur,Nomor GIGI, Catatan Kasir dan Catatan Keterangan Payment, Dimunculkan di NOTA."},
		],tgl_updated:"2024-12-06"},
		{version:"4.4.0",updated_arr:[
			{teks_menu:"Klinik Sistem >> Pasien >> Data Pasien >> Billing Periksa",teks_content:"Update Fitur, Untuk Asuransi yang ditentukan Maksimal Jumlah Terapi-nya."+
				"<br/>Diskon Total Dihilangkan, Diganti DISKON PER ITEM, dengan PERSENTASE Tetap DIBATASI."},
			{teks_menu:"Klinik Sistem >> Kasir >> Pembayaran Kasir >> Input Pembayaran",teks_content:"Update Fitur, View Nomor Asuransi.<br/>Multiple Jenis METODE PEMBAYARAN Dalam SATU NOTA.<br/>Diskon Total Dihilangkan, dan memunculkan DISKON per Item Terapi."},
			{teks_menu:"Klinik Sistem >> Kasir >> Bayar Cicilan Pasien",teks_content:"Update Fitur, Load Detil Item, Menampilkan Diskon per Item Terapi."},
			{teks_menu:"Klinik Sistem >> Kasir >> Rekap Nota",teks_content:"Update Fitur, View Multiple Jenis METODE PEMBAYARAN Dalam SATU NOTA.<br/>Diskon Total Dihilangkan, dan memunculkan DISKON per Item Terapi."},
			{teks_menu:"Laporan >> Operasional >> Laporan Kasir",teks_content:"Update Fitur, View Rincian Item Terapi Tiap Transaksi dengan Diskon Terapinya, dan Menyesuaikan Multiple Metode Pembayaran."},
			{teks_menu:"Laporan >> Operasional >> Laporan Laba/Rugi",teks_content:"Update Fitur, Menyesuaikan Diskon Tiap Terapi, dan Multiple Biaya Payment dari masing-masing metode pembayaran."},
			{teks_menu:"Laporan >> Klinik >> Laporan Fee Dokter",teks_content:"Update Fitur, Menyesuaikan Diskon Tiap Terapi, dan Multiple Biaya Payment dari masing-masing metode pembayaran."},
			{teks_menu:"Laporan >> Klinik >> Laporan Terapi",teks_content:"Update Fitur, Menyesuaikan Diskon Tiap Terapi."},
		],tgl_updated:"2024-11-22"},
		{version:"4.3.0",updated_arr:[
			{teks_menu:"Master Data >> Data Sistem >> Tarif Terapi",teks_content:"Update Fitur, Untuk HAK User DOKTER Hanya bisa melihat TOTAL Tarif."},
			{teks_menu:"Master Data >> Data Sistem >> Data Asuransi",teks_content:"Update Fitur, Tiap Asuransi diberi batasan Jumlah Maksimal Terapi."},
			{teks_menu:"Klinik Sistem >> Pasien >> Input Pasien",teks_content:"Update Fitur, Nomor Asuransi Pasien."},
			{teks_menu:"Klinik Sistem >> Pasien >> Data Pasien >> Dialog Pasien",teks_content:"Update Fitur, Memunculkan No.Asuransi Pasien."},
			{teks_menu:"Klinik Sistem >> Kunjungan Pasien >> Daftar Pasien",teks_content:"Update Fitur, SET Kehadiran Berlaku ke SEMUA Window yang Saat Tebuka Bersamaan."},
			{teks_menu:"Klinik Sistem >> Pasien >> Data Pasien >> Rekam Medis",teks_content:"Update Fitur, Tampilan Dibuat simultan seluruh Cabang, tidak lagi per Cabang saja."},
		],tgl_updated:"2024-11-15"},
		{version:"4.2.x",updated_arr:[
			{teks_menu:"Aplikasi",teks_content:"Seluruh Update Terakhir yang BELUM terdokumentasi."},
		],tgl_updated:"2024-11-14"},
	]
	const uJmlData	= uDatalogsArr.length;

	//---HANDEL--/
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);

		const vDataviewArr	= uDatalogsArr.slice(vMin,(vMax));

		return (
		<>
		{vDataviewArr.map((vItems,vKeys)=>{
			const vItemsArr	= vItems.updated_arr || [];

			return (
			<CCard className="mb-3 classbgcard" key={vKeys}>
			<CCardHeader className="d-flex justify-content-between">
				<div>Ver: <b>{vItems.version}</b></div>
				<div className="classfontsmaller"><small>{UFunc.HariAngka(vItems.tgl_updated)}</small></div>
			</CCardHeader>
			<CCardBody>
			<ul>
			{vItemsArr.map((vItemcontent,vKeycontent)=>{
				return (
				<li key={vKeycontent}>
					<div className="fw-bolder">{vItemcontent.teks_menu}</div>
					<small className="d-block fst-italic">{UFunc.renderHTML(vItemcontent.teks_content)}</small>
				</li>
				)//>
			})}
			</ul>
			</CCardBody>
			</CCard>
			)//>
		})}
		</>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
    	
    	apiLoaddata();

    	return () => {
    		setPageActive(1);
    	}
	},[])
	//---END EFFECT--/

	//console.log("(Updatedlog) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadertop border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">
				Updated Logs<span className="d-none d-md-inline ms-1">{pjson.name}</span>
				</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className="px-0 mb-3">
			{contentMain()}

			{(uJmlData > uMaxData) && (
			<CCard>
			<CCardBody>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardBody>
			</CCard>
			)}
		</CContainer>
		</div>
		</div>
		</>
	)//>
}	

export default Updatedlog